// =========================================
// ============ Footer styles ==============
// =========================================
footer {
  .line {
    height: 2px;
    width: 100%;
    background: #ffffff;
    &.bottom {
      width: 80%;
      margin: 0 auto;
    }
    &.top {
      display: none;
      @include respond-to("small") {
        display: block;
        width: 100%;
        margin: 20px auto;
      }
    }
  }
  background-color: #1c1c1c;
  padding: 50px 0 20px;
  .footer-top {
    display: flex;
    align-items: center;
    padding: 0 10%;
    img {
      margin-right: 80px;
      filter: grayscale(1);
      @include respond-to("small") {
        margin-right: 20px;
      }
    }
  }
  .footer-center {
    display: flex;
    align-items: flex-start;
    border: none;
    margin: 50px 0;
    padding: 0 10%;
    justify-content: space-between;
    @include respond-to("small") {
      display: block;
    }
    .address-menu {
      ul {
        list-style: none;

        li {
          a {
            display: flex;
            .icon-img {
              img {
                filter: invert(1);
                width: 10%;
                width: 30%;
                justify-content: center;
                display: flex;
                margin: 2px auto 0px;
              }
            }
            .label-des {
              p {
                font-size: 14px;
                color: #ffffff;
                text-decoration: none;
                text-align: left;
                font-weight: 200;
              }
            }
          }
        }
      }
    }
    .address {
      //width: 30%;
      width: 27%;
      @include respond-to("large_1300") {
        width: 22%;
      }
      @include respond-to("medium_lg") {
        margin-right: 30px;
      }
      @include respond-to("medium") {
        margin-right: 30px;
        width: 33%;
      }
      @include respond-to("small") {
        width: 100%;
      }
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        li {
          a {
            display: flex;
            text-align: left;
            align-items: flex-start;
            margin-bottom: 43px;
            font-size: 14px;
            font-weight: 200;
            color: #ffffff;
            text-decoration: none;
            img {
              width: 16px;
              filter: invert(1);
              margin: 0px 14px;
              @include respond-to("small") {
                display: none;
              }
            }
            .fa {
              &.fa-map-marker {
                margin-right: 20px;
                margin-top: 6px;
              }
              &.fa-phone {
                margin-right: 17px;
                margin-top: 6px;
              }
              &.fa-envelope {
                margin-right: 17px;
                margin-top: 6px;
              }
              @include respond-to("small") {
                display: none;
              }
            }

            // a {
            //   color: #ffffff;
            //   text-decoration: none;
            // }
          }
        }
      }
    }
    .menu {
      // width: 27%;
      width: 25%;
      margin: 0 auto;
      @include respond-to("large_1300") {
        width: 25%;
      }
      @include respond-to("medium_lg") {
        margin-left: 25px;
      }
      @include respond-to("medium") {
        margin-left: 15px;
        width: 24%;
      }
      @include respond-to("small") {
        width: 100%;
        margin-left: 0px;
      }
      ul {
        list-style: none;
        li {
          margin-bottom: 5px;
          text-align: left;
          a {
            font-size: 14px;
            color: #ffffff;
            text-decoration: none;
            text-align: left;
            font-weight: 200;
            cursor: pointer;
          }
        }
      }
    }
    .menu2 {
      width: 20%;
      margin: 0 auto;
      @include respond-to("large_1300") {
        width: 24%;
      }
      @include respond-to("medium_lg") {
        margin-left: 25px;
      }
      @include respond-to("medium") {
        margin-left: 15px;
        width: 24%;
      }
      @include respond-to("small") {
        width: 100%;
        margin-left: 0px;
      }
      ul {
        list-style: none;
        li {
          margin-bottom: 5px;
          text-align: left;
          a {
            font-size: 14px;
            color: #ffffff;
            text-decoration: none;
            text-align: left;
            font-weight: 200;
            cursor: pointer;
          }
        }
      }
    }
    .icon {
      width: 15%;
      @include respond-to("small") {
        width: 100%;
        margin: 0px auto;
      }
      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include respond-to("small") {
          justify-content: space-between;
        }
        li {
          margin-right: 80px;
          @include respond-to("medium") {
            margin-right: 10px;
          }
          a {
            cursor: pointer;
            img {
              @include respond-to("medium") {
                width: 30px;
              }
            }
          }
        }
      }
    }
    .cert {
      width: 20%;
      text-align: right;
    }
  }
  .footer-bottom {
    text-align: center;
    //border-top: 1px solid #ffffff;
    //margin-top: 50px;
    @include respond-to("small") {
      width: 40%;
      margin: 0 auto;
    }
    p {
      font-size: 14px;
      font-weight: 200;
      color: #ffffff;
      padding-top: 25px;
      padding-bottom: 10px;
      text-align: center;
      @include respond-to("small") {
        font-size: 10px;
        font-weight: 300;
      }
    }
    img {
      background-color: #292c2f;
    }
    .footer-copyright {
      background-color: #292c2f;
      color: #ffffff;
    }
  }
}
