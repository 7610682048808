//===============================================================
//================sector expertise inner page styles=============
//===============================================================

.sector-expertise-card-wrapper {
  //margin-top:-30px;
  //margin-bottom: 100px;
  @include respond-to("large_1300") {
    padding-top: 32px;
  }
  @include respond-to("medium") {
    padding-top: 80px;
  }
  @include respond-to("small") {
    padding-top: 50px;
  }
  @include respond-to("exsmall_574") {
    // padding-top: 0px;
  }

  .main-content {
    transition-duration: 0.25s;
    transition-delay: 0.01s;
    transition-timing-function: ease-in-out;
    transform-origin: 0%;
    margin-bottom: 70px;
    &.active {
      @include inner_card_fade;
      transform-origin: 0%;
      @include respond-to("ex_large") {
        margin-bottom: 90px;
      }
      @include respond-to("ex_large_1600") {
        margin-bottom: 70px;
      }
      .description-block-expertise {
        display: none;
      }
    }
    .description-block-expertise {
      width: 60%;
      margin: 0 auto;
      padding: 0 100px;
      margin-bottom: 70px;
      @include respond-to("ex_large") {
        margin-bottom: 90px;
      }
      @include respond-to("ex_large_1600") {
        margin-bottom: 70px;
      }
      @include respond-to("ex_large_max") {
        width: 55%;
      }
      @include respond-to("medium") {
        width: 70%;
        padding: 0 5px;
      }
      @include respond-to("small") {
        padding: 0px;
        width: 95%;
      }
      .expertise-card-container {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        width: 100%;
        height: 175px;
        background-color: #ffffff;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        position: relative;
        &:hover {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        }
        @include respond-to("ex_large") {
          height: 210px;
        }
        @include respond-to("ex_large_1600") {
          height: 185px;
        }
        @include respond-to("ex_large_max") {
          height: 170px;
        }
        @include respond-to("large") {
          height: 145px;
        }
        @include respond-to("medium") {
          height: 130px;
        }
        .card-left-side {
          display: flex;
          .card-img-wrapper {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            transition: 0.3s;
            width: 18%;
            background-color: #ffffff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            margin-left: 100px;
            margin-top: -50px;
            padding: 10px;
            @include respond-to("ex_large_max") {
              margin-top: -30px;
              margin-left: 60px;
              height: 132px;
              position: relative;
            }
            @include respond-to("large") {
              height: 110px;
              margin-left: 50px;
            }
            @include respond-to("medium") {
              height: 95px;
              margin-left: 40px;
            }
            @include respond-to("exsmall_574") {
              margin-left: 15px;
              width: 40%;
              height: 120px;
            }
            @include respond-to("exsmall") {
              margin-left: 15px;
              width: 40%;
              height: 110px;
            }

            .inner-card-img {
              object-fit: cover;
              width: 65%;
              justify-content: center;
              margin: 0 auto;
              padding: 27px;
              @include respond-to("ex_large_max") {
                padding: 15% 16%;
                width: 54%;
                position: absolute;
                justify-content: center;
                align-items: center;
                margin: auto;
              }
              @include respond-to("large") {
                width: 50%;
                padding: 17% 16%;
              }
              @include respond-to("medium") {
                width: 60%;
                padding: 12% 11%;
              }
            }
          }
          .card-right-side {
            //width: 70%;
            display: flex;
            @include respond-to("ex_large_max") {
              // width: 80%;
            }
            @include respond-to("small") {
              width: 70%;
            }
            .lable-name {
              margin: 40px;
              width: 65%;
              @include respond-to("ex_large") {
                width: 68%;
                margin: 45px;
              }
              @include respond-to("ex_large_1600") {
                width: 69%;
                margin: 40px;
              }
              @include respond-to("ex_large_max") {
                margin: 35px;
                width: 69%;
              }
              @include respond-to("large") {
                margin: 30px;
                width: 69%;
              }
              @include respond-to("medium") {
                margin: 15px;
                width: 82%;
              }
              @include respond-to("small") {
                width: 55%;
                margin: 15px;
              }

              .name {
                color: $black-color;
                font-size: 18px;
                font-weight: 600;
                @include respond-to("ex_large") {
                  font-size: 24px;
                }
                @include respond-to("ex_large_1600") {
                  font-size: 24px;
                }
                @include respond-to("ex_large_max") {
                  font-size: 20px;
                }
                @include respond-to("large") {
                  font-size: 18px;
                }
                @include respond-to("small") {
                  font-size: 12px;
                }
              }
            }
            .arrow-btn {
              position: absolute;
              // margin-top: 95px;
              // margin-left: 330px;
              margin-top: 8%;
              margin-left: 50%;
              @include respond-to("ex_large_max") {
                margin-left: 50%;
                margin-top: 10%;
              }
              @include respond-to("medium") {
                margin-left: 53%;
              }
              @include respond-to("exsmall_574") {
                margin-top: 13%;
                margin-left: 35%;
              }
              @include respond-to("exsmall") {
                margin-top: 17%;
                margin-left: 33%;
              }

              img {
                // margin-top: 60%;
                // margin-left: 450%;
                width: 80%;
                cursor: pointer;
                @include respond-to("ex_large") {
                  width: 115%;
                }
                @include respond-to("ex_large_1600") {
                  width: 80%;
                }
              }
            }
          }
        }
      }
    }

    .expertise-card {
      width: 100%;
      transition-duration: 0.25s;
      transition-delay: 0.01s;
      transition-timing-function: ease-in-out;
      transform-origin: 0%;
      @include inner_card_fade;
      .expertise-inner {
        width: 70%;
        margin: 30px auto 50px;

        @include respond-to("ex_large_max") {
          width: 70%;
        }
        @include respond-to("medium") {
          width: 83%;
        }
        @include respond-to("small") {
          width: 95%;
        }
        .expertise-card-content-wrapper {
          border-color: #ffffff;
          border-style: solid;
          border-width: 1px;
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
          border-radius: 8px;

          .card-top {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin: 10px auto;
            padding: 40px;
            width: 90%;
            @include respond-to("medium") {
              padding: 30px;
            }
            @include respond-to("small") {
              width: 95%;
              padding: 0;
            }
            .card-img {
              padding: 10px 40px;

              @include respond-to("large") {
                padding: 10px 30px;
              }
              @include respond-to("ex_large_max") {
                padding: 10px 18px;
              }
              @include respond-to("medium") {
                padding: 10px;
              }
              @include respond-to("small") {
                padding: 0px;
                width: 20%;
              }
              .card-logo-img {
                width: 100%;
                @include respond-to("ex_large") {
                  width: 125%;
                }
                @include respond-to("ex_large_1600") {
                  width: 100%;
                }
                @include respond-to("medium") {
                  width: 90%;
                }
                @include respond-to("small") {
                  width: 100%;
                }
              }
            }
            .card-title {
              margin: 10px;
              width: 45%;
              padding: 0 20px;
              @include cad_text_faid;
              @include respond-to("ex_large") {
                width: 45%;
                padding: 0 50px;
              }
              @include respond-to("ex_large_1600") {
                width: 55%;
                padding: 0 20px;
              }
              @include respond-to("ex_large_max") {
                width: 60%;
                padding: 0 10px;
              }
              @include respond-to("medium") {
                padding: 0px;
                width: 68%;
              }
              @include respond-to("small") {
                //padding: 0;
                //margin: 0;
              }
              .title {
                color: $black-color;
                font-size: 24px;
                font-weight: 500;
                @include respond-to("ex_large") {
                  font-size: 36px;
                }
                @include respond-to("ex_large_1600") {
                  font-size: 30px;
                }
                @include respond-to("large") {
                  font-size: 28px;
                }
                @include respond-to("small") {
                  font-size: 14px;
                  margin-top: 15px;
                }
              }
            }
            .arrow-top {
              position: absolute;
              margin-left: 80%;
              cursor: pointer;
              @include respond-to("ex_large_max") {
                margin-left: 75%;
              }
              @include respond-to("large") {
                //margin-left: 68%;
              }
              @include respond-to("medium") {
                margin-left: 82%;
              }
              @include respond-to("small") {
                margin-left: 85%;
              }
              .arrow-up {
                width: 80%;
                @include respond-to("ex_large") {
                  width: 110%;
                }
                @include respond-to("ex_large_1600") {
                  width: 80%;
                }
                @include respond-to("medium") {
                  width: 87%;
                }
                @include respond-to("small") {
                  width: 92%;
                }
              }
            }
          }
          .card-middle {
            margin: 0 90px;
            padding: 0 40px;
            width: 70%;
            @include respond-to("ex_large") {
              width: 80%;
            }
            @include respond-to("ex_large_1600") {
              width: 75%;
              padding: 0 40px;
            }
            @include respond-to("ex_large_max") {
              width: 80%;
              margin: 0 auto;
            }
            @include respond-to("medium") {
              width: 90%;
            }
            @include respond-to("small") {
              padding: 0px;
              width: 100%;
            }
            .middle-list {
              display: flex;
              margin: 0 auto;
              padding: 10px 55px;
              width: 90%;
              justify-content: center;
              @include respond-to("ex_large_max") {
                padding: 10px 50px;
                width: 85%;
              }
              @include respond-to("large") {
                padding: 10px 50px;
              }
              @include respond-to("medium") {
                padding: 10px 30px;
              }
              @include respond-to("small") {
                padding: 10px 0px;
              }
              .list1 {
                font-size: 14px;
                line-height: 28px;
                @include respond-to("ex_large") {
                  font-size: 16px;
                  line-height: 40px;
                }
                @include respond-to("ex_large_1600") {
                  font-size: 16px;
                  line-height: 30px;
                }
                @include respond-to("ex_large_max") {
                  font-size: 14px;
                }
                @include respond-to("small") {
                  font-size: 12px;
                }
                .list-ul {
                  padding: 0 45px;
                  @include respond-to("ex_large") {
                    padding: 0 65px;
                  }
                  @include respond-to("ex_large_1600") {
                    padding: 0 40px;
                  }
                  @include respond-to("ex_large_max") {
                    padding: 0 25px;
                  }
                  @include respond-to("large") {
                    padding: 0 18px;
                  }
                  @include respond-to("medium") {
                    padding: 0 20px;
                  }
                  @include respond-to("exsmall_320") {
                    padding: 0 13px;
                  }
                }
              }
              .list2 {
                font-size: 14px;
                line-height: 28px;
                @include respond-to("ex_large") {
                  font-size: 16px;
                  line-height: 40px;
                }
                @include respond-to("ex_large_1600") {
                  font-size: 16px;
                  line-height: 30px;
                }
                @include respond-to("ex_large_max") {
                  font-size: 14px;
                }
                @include respond-to("small") {
                  font-size: 12px;
                }
                .list-ul2 {
                  padding: 0 45px;
                  @include respond-to("ex_large") {
                    padding: 0 65px;
                  }
                  @include respond-to("ex_large_1600") {
                    padding: 0 40px;
                  }
                  @include respond-to("ex_large_max") {
                    padding: 0 25px;
                  }
                  @include respond-to("large") {
                    padding: 0 18px;
                  }
                  @include respond-to("medium") {
                    padding: 0 20px;
                  }
                  @include respond-to("exsmall_320") {
                    padding: 0 10px;
                  }
                }
              }
              .list3 {
                font-size: 14px;
                line-height: 28px;
                @include respond-to("ex_large") {
                  font-size: 16px;
                  line-height: 40px;
                }
                @include respond-to("ex_large_1600") {
                  font-size: 16px;
                  line-height: 30px;
                }
                @include respond-to("ex_large_max") {
                  font-size: 14px;
                }
                @include respond-to("small") {
                  display: none;
                }
                .list-ul3 {
                  padding: 0 45px;
                  @include respond-to("ex_large") {
                    padding: 0 65px;
                  }
                  @include respond-to("ex_large_1600") {
                    padding: 0 40px;
                  }
                  @include respond-to("ex_large_max") {
                    padding: 0 25px;
                  }
                  @include respond-to("large") {
                    padding: 0 18px;
                  }
                  @include respond-to("medium") {
                    padding: 0 20px;
                  }
                }
              }
            }
          }
          .card-bottom {
            width: 100%;
            .logo-cards-wrapper {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              padding: 50px 270px 60px;
              justify-content: center;
              @include respond-to("ex_large") {
                padding: 50px 330px 75px;
              }
              @include respond-to("ex_large_1600") {
                padding: 50px 270px 60px;
              }
              @include respond-to("ex_large_max") {
                padding: 40px 250px;
              }
              @include respond-to("large") {
                padding: 30px 180px 35px;
              }
              @include respond-to("medium") {
                padding: 30px 140px 40px;
              }
              @include respond-to("small") {
                padding: 20px 0px 30px;
              }
              .logo-card {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                width: 20%;
                background-color: #ffffff;
                border-radius: 4px;
                margin: 10px;
                padding: 30px 20px;
                @include respond-to("ex_large_max") {
                  padding: 25px 12px;
                }
                @include respond-to("exsmall_320") {
                  padding: 25px 10px;
                  margin: 8px;
                }
                .logo-card-img {
                  margin: 0 auto;
                  .expertise-img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.active {
      display: block;
      visibility: visible;
    }
  }
}

.case-studies-wrapper {
  background-color: #ffffff;
  width: 100%;
  // margin-bottom: -170px;
  .case-studies-cards-wrapper {
    margin: 50px auto;
    .case-studies-cards-block {
      width: 90%;
      margin: 0 auto;
      padding: 0 50px;
      margin-bottom: 70px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      //flex-direction: column;
      @include respond-to("medium") {
        width: 90%;
        padding: 0 35px;
      }
      @include respond-to("small") {
        width: 90%;
        padding: 0 10px;
      }
      .case-studies-card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        width: 40%;
        height: 176px;
        background-color: #ffffff;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        margin: 10px;
        @include respond-to("ex_large") {
          height: 330px;
          margin: 20px;
        }
        @include respond-to("ex_large_1600") {
          height: 240px;
          margin: 12px;
        }
        @include respond-to("large") {
          height: 200px;
        }
        @include respond-to("medium") {
          height: 130px;
        }
        @include respond-to("small") {
          height: 23vh;
          width: 90%;
        }
        .first-card {
          .first-img {
            height: 176px;
            width: 100%;
            border-radius: 8px;
            // opacity: 0.5;
            @include respond-to("ex_large") {
              height: 330px;
            }
            @include respond-to("ex_large_1600") {
              height: 240px;
            }
            @include respond-to("large") {
              height: 200px;
            }
            @include respond-to("medium") {
              height: 130px;
            }
            @include respond-to("small") {
              height: 23vh;
            }
          }
          .second-img {
            position: relative;
            width: 65%;
            padding: 0 75px;
            margin-top: 10px;
            margin-top: -170px;
            /* margin: -52px auto; */
            margin-bottom: 60px;
            // margin: 0 auto;
            // margin-top: -45%;
            @include respond-to("ex_large") {
              margin-top: -310px;
              padding: 0 80px;
              width: 75%;
            }
            @include respond-to("ex_large_1600") {
              margin-top: -230px;
              padding: 0 81px;
              width: 70%;
            }
            @include respond-to("ex_large_max") {
              padding: 0 72px;
              margin-bottom: 42px;
              justify-content: center;
              align-items: center;
            }
            @include respond-to("large") {
              padding: 0 63px;
              margin-bottom: 35px;
              justify-content: center;
              align-items: center;
            }
            @include respond-to("medium_lg") {
              padding: 0 45px;
              margin-bottom: 35px;
              width: 80%;
            }
            @include respond-to("medium") {
              padding: 0 30px;
              margin-bottom: 22px;
              width: 80%;
            }

            @include respond-to("small") {
              padding: 0 30px;
              margin-bottom: 28px;
            }
            @include respond-to("exsmall_574") {
              padding: 0 30px;
              margin-bottom: 33px;
            }
            @include respond-to("exsmall") {
              padding: 0 30px;
              margin-bottom: 28px;
            }
            @include respond-to("exsmall_320") {
              padding: 0 30px;
              margin-bottom: 28px;
            }
          }
        }
        // .second-card{

        //     .second-img{
        //         width: 100%;
        //         position: absolute;
        //         margin: 0 auto;
        //         justify-content: center;
        //         align-items: center;
        //     }
        // }
      }
    }
  }
}
