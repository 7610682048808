// =========================================
// =============== Variables ===============
// =========================================

//----- Theme color -----
$primary-color: #f37021;
$secondary-color: #fddb31;
$primary-hover: #e79663;
$background-color: #f8f9f9;
$white-color: #fff;
$black-color: #000000;
$text-color: #3d3d3d;

$success: #4caf50;
$danger: #f44336;
$warning: #ffc107;
$info: #17a2b8;
$light: #f8f9fa;
$dark: #343a40;

//----- Socail Color -------
$android: #7ac157;
$apple: #b8b8b8;
$behance: #1869ff;
$dribbble: #ea4c8a;
$dropbox: #007ee5;
$evernote: #78d525;
$facebook: #4867aa;
$github: #313131;
$google-drive: #1da462;
$google-earth: #4285f4;
$google-glass: #ea4335;
$google-maps: #5083c3;
$google-play: #01b9fd;
$google-plus: #dd5144;
$google: #4285f4;
$instagram: #b23a94;
$css3: #0277bd;
$html5: #e44d26;
$javascript: #f9dc3d;
$python: #0c9dbf;
$lastfm: #e31b23;
$linkedin: #007bb6;
$paypal: #002f86;
$pinterest: #bd081b;
$pocket: #ef3e56;
$polymer: #f87292;
$rss: #f99c3a;
$share: #2c9cff;
$stackoverflow: #f38024;
$steam: #15497b;
$twitter: #1da1f2;
$vk: #5181b8;
$wikipedia: #e9e9e9;
$windows: #0078d6;
$s8tracks: #122d4b;
$amazon: #f79b34;
$blogger: #f06a35;
$delicious: #0000fe;
$disqus: #2e9efe;
$flattr: #7ab831;
$flickr: #fe0084;
$odnoklassniki: #f58220;
$outlook: #0072c6;
$playstation: #07418e;
$reddit: #ff4500;
$skype: #00a9f0;
$slideshare: #0077b5;
$soundcloud: #fe4900;
$tumblr: #36465d;
$twitch: #6441a4;
$vimeo: #1ab7ea;
$whatsapp: #189d0e;
$xbox: #107c0f;
$yahoo: #4101af;
$youtube: #fe0000;

//----- Font size -----
$section-title: 36px;

// =========================================
// =Mobile responsive settings code snippet=
// =========================================
$breakpoints: (
  "exsmall_320": (
    max-width: 325px,
  ),
  "exsmall": (
    max-width: 375px,
  ),
  "exsmall_574": (
    max-width: 574px,
  ),
  "small": (
    max-width: 767px,
  ),
  "medium": (
    max-width: 991px,
  ),
  "medium_lg": (
    max-width: 1024px,
  ),
  "large": (
    max-width: 1199px,
  ),
  "large_1300": (
    max-width: 1300px,
  ),
  "ex_large_max": (
    max-width: 1440px,
  ),
  "ex_large_1600": (
    max-width: 1600px,
  ),
  "ex_large": (
    max-width: 1920px,
  ),
  "min768": (
    min-width: 768px,
  ),
) !default;
