.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: $white-color;
  opacity: 0.7;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  width: 50%;
  // position: absolute;
  top: 50%;
  left: 50%;
  /* margin-right: -50%; */
  transform: translate(-50%, -50%);
  margin: 0px auto;
  @include respond-to("medium_lg") {
    width: 70%;
  }
  @include respond-to("small") {
    width: 95%;
  }
  .modal {
    z-index: 100;
    background: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 3px;
    padding: 20px 40px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

    @include respond-to("medium") {
      // width: 80%;
    }
    @include respond-to("small") {
      // width: 80%;
      padding: 20px 28px;
    }
    .modal-header {
      display: flex;
      justify-content: flex-end;
      .modal-close-button {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        opacity: 0.3;
        cursor: pointer;
        border: none;
        background-color: $white-color;
      }
      .button-default {
        background: #247ba0;
        color: #fff;
      }
    }
    .modal-body {
      .modal-footer {
        p {
          font-size: 12px;
          a {
            color: $primary-color;
            text-decoration: none;
          }
        }
      }
    }
  }
}
.form {
  .discription-text {
    p {
      text-align: center;
    }
  }
  .input-box-wrap {
    display: flex;
    @include respond-to("small") {
      display: flex;
      flex-flow: column;
    }
    .name {
      width: 46%;
      margin: 0px auto;
      @include respond-to("small") {
        width: 100%;
      }
      input {
        width: 100%;
        color: #c2c2c2;
        //   padding: 12px 0px;
        margin: 8px 0;
        box-sizing: border-box;
        border: none;
        border-bottom: 2px solid #e5e5e5;
        font-size: 14px;
        font-weight: 300;
        @include respond-to("small") {
          width: 97%;
        }
      }
      input:focus {
        width: 97%;
        color: $black-color;
        //   padding: 12px 0px;
        margin: 8px 0;
        outline: none;
        border-bottom: 2px solid #e5e5e5;
        font-size: 14px;
        font-weight: 300;
        opacity: 0.8;
      }
    }
  }
  .fullwidth {
    display: block;
    width: 100%;
    padding: 0px 12px;
    @include respond-to("small") {
      width: 100%;
      padding: 0px;
    }

    input[type="text"] {
      width: 90%;
      color: #c2c2c2;
      //   padding: 12px 0px;
      margin: 8px 0;
      box-sizing: border-box;
      border: none;
      border-bottom: 2px solid #e5e5e5;
      font-size: 14px;
      font-weight: 300;
      @include respond-to("small") {
        width: 97%;
      }
    }
  }
  input[type="text"]:focus {
    width: 97%;
    color: $black-color;
    //   padding: 12px 0px;
    margin: 8px 0;
    outline: none;
    border-bottom: 2px solid #e5e5e5;
    font-size: 14px;
    font-weight: 300;
    opacity: 0.8;
  }

  input[type="submit"] {
    background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px 0;
    font-size: 15px;
    font-weight: 500;
    @include respond-to("small") {
      align-items: center;
      text-align: center;
      padding: 12px 40px;
      // margin: 10% 30%;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #222222;
    padding-bottom: 15px;
    display: inline-block;
    padding-top: 30px;
    @include respond-to("small") {
      padding-bottom: 0;
    }
  }
  .checkbox-label {
    padding-top: 0px;
  }
  .checkbox-container {
    padding: 12px 0px;
    .check-item {
      display: flex;
      label {
        @include respond-to("small") {
          font-size: 12px;
        }
      }
    }
  }
}
