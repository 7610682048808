@mixin scrollcarousel {
  &::-webkit-scrollbar {
    display: none;
  }
}

.representative-buyers {
  background-image: linear-gradient(to bottom, #f3f3f3, rgba(243, 243, 243, 0));
  width: 100%;
  padding-top: 50px;
  //     @include respond-to("ex_large") {
  //       width: 100%;
  //       background-size: cover;
  //    }
  @include respond-to("medium") {
    padding-top: 40px;
  }
  @include respond-to("exsmall_574") {
    padding-top: 30px;
  }

  .representative-buyers-details {
    .representative-buyers-para {
      font-size: 16px;
      text-align: center;
      color: $black-color;
      font-weight: 300;
      width: 55%;
      margin: 0 auto;
      margin-bottom: 60px;
      @include respond-to("medium_lg") {
        margin-bottom: 40px;
      }

      @include respond-to("small") {
        width: 80%;
        margin-bottom: 30px;
        font-size: 14px;
      }
    }
  }
  .icon-back-img {
    width: 100%;
    object-fit: cover;
    //margin-top: 50px;
    background-size: contain;
  }

  .icon2-back-img {
    width: 100%;
    object-fit: cover;
    margin-top: -180px;
    background-size: contain;
  }
  .representative-buyers-icons {
    padding: 0 0 120px 0;
    margin: 0 auto;
    width: 80%;
    display: flex;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    // @include respond-to("ex_large_max") {
    //   margin-bottom: 450px;
    //  }

    .representative-buyers-icons-box {
      width: 82%;
      margin: 0 auto;
      // //flex-wrap: wrap;
      justify-content: center;
      display: flex;
      align-items: flex-start;
      //position: relative;
      //overflow: hidden;
      .itemIcon-wrapper {
        display: flex;

        .Item_wrapper {
          transition-duration: 0.3s;
          // margin-left: 186px;
          &.open {
            //margin-right: 380px;
          }
          &.none-active {
            //position: absolute;
          }
          .ItemIcon {
            z-index: 99;
            position: relative;
            left: 0;
            width: 150px;
            height: 150px;
            object-fit: contain;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
            border-radius: 8px;
            margin: 15px;
            cursor: pointer;
            transition-duration: 0.3s;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            img {
              transition-duration: 0.3s;
              width: calc(100% - 86px);
              padding: 18px;
              object-fit: contain;
              object-position: center;
            }
            &.open {
              left: 34px;
              background-color: $white-color;
              //transform: translateX(170px);
            }
          }
          .dataCard_wrapper {
            //position: absolute;
            height: 0;
            width: 0;

            overflow: hidden;

            transform: translateX(-22px) translateY(-190px);
            visibility: hidden;
            opacity: 0;

            transition-duration: 0.3s;
            &.open {
              //@include inner_card_fade;
              width: 457px;
              height: 440px;
              visibility: visible;
              opacity: 1;

              transform: translateX(0px) translateY(-123px);
              .dataCard {
                //@include card_desc_fade;
              }
            }
            .dataCard {
              margin: 10px;
              width: calc(100% - 20px);
              //height: 470px;
              box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
              border-radius: 8px;
              background-color: #fff;
              box-sizing: border-box;
              padding: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              cursor: pointer;

              .title-section {
                transition-delay: 0.3s;
                padding-left: 48%;
                .name {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 0.8;
                }
                .title {
                  font-size: 17px;
                  font-weight: normal;
                }
                a {
                  button {
                    background: linear-gradient(
                      343deg,
                      #f37021 6%,
                      #fddb31 95%
                    );
                    border: none;
                    display: inline-block;
                    text-align: center;
                    border-radius: 4px;
                    padding: 8px 30px;
                    width: 125px;
                    font-size: 16px;
                    font-weight: 300;
                    outline: none;
                    cursor: pointer;
                    color: #ffffff;
                    margin: 8px auto;
                  }
                }
              }
              .desc_section {
                transition-delay: 0.3s;
                padding: 14px;
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .representative-buyers-icons-card {
        text-align: center;
        margin: auto;
        .representative-buyers-icon-image {
          cursor: pointer;
          width: 30%;
          min-height: 60px;
          background-color: $white-color;
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
          border-radius: 12px;
          padding: 10%;
          margin: 0 auto;
          align-items: center;

          .icon-span {
            display: inline-block;

            .icon-img {
              align-items: center;
              margin: 20px auto;
            }
            .icon-img2 {
              align-items: center;
              margin: 20px auto;
              width: 60%;
            }
          }
        }
      }

      .representative-buyers-icon-image-arrow {
        cursor: pointer;
        width: 30%;
        padding: 10%;
        margin: 0 auto;
        align-items: center;
        .curve-span {
          // display: inline-block;
          border-radius: 50%;
          padding: 5px;
          border: 1px solid $black-color;
          .arrow-img {
            width: 30%;
          }
        }
      }
    }
  }
  // @media screen and (min-width: 1600px) {
  //   .representative-buyers-icons{margin-bottom: 450px;}
  // }

  .team-description {
    p {
      width: 55%;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      color: $black-color;
      padding-bottom: 50px;
      @include respond-to("medium") {
        padding-bottom: 40px;
      }
      @include respond-to("exsmall_574") {
        width: 80%;
        padding-bottom: 0px;
        font-size: 14px;
      }
    }
  }
  .resume {
    padding: 10px auto;
    margin: 0 auto;
    .team_linkedin {
      width: 53%;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: $black-color;
      text-align: center;

      @include respond-to("medium_lg") {
        width: 61%;
      }
      @include respond-to("medium") {
        width: 80%;
      }
      @include respond-to("small") {
        width: 80%;
        font-size: 11px;
      }
      @include respond-to("exsmall_574") {
        width: 95%;
        font-size: 11px;
      }
      @include respond-to("exsmall") {
        width: 95%;
        font-size: 11px;
      }
      @include respond-to("exsmall_320") {
        width: 95%;
        font-size: 10px;
      }
      a {
        color: $black-color;
        text-decoration: none;
        padding: 0px 4px;
        &:hover {
          color: $primary-color;
        }
      }
    }
    .team_mail {
      width: 53%;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: $black-color;
      text-align: center;
      margin-bottom: 50px;
      // @include respond-to("medium_lg") {
      //   width: 61%;

      // }
      @include respond-to("medium") {
        width: 70%;
        margin-bottom: 40px;
      }
      @include respond-to("small") {
        width: 80%;
        font-size: 11px;
        margin-bottom: 30px;
      }
      @include respond-to("exsmall_574") {
        width: 90%;
        font-size: 11px;
      }
      @include respond-to("exsmall") {
        width: 95%;
        font-size: 11px;
      }
      @include respond-to("exsmall_320") {
        width: 95%;
        font-size: 10px;
      }
      a {
        color: $primary-color;
        text-decoration: none;
        padding: 0px 4px;
      }
    }
  }
}
//===========================================================//
//===================select transaction styles ==============//
//===========================================================//
.recent-transction-section-wrapper {
  margin: 50px 0px 50px 0px;
  @include respond-to("medium_lg") {
    margin: 30px 0px 30px 0px;
  }
  @include respond-to("small") {
    margin: 30px 0px 0px 0px;
  }
  @include respond-to("exsmall_320") {
    margin: 30px 0px 0px 0px;
  }
  .transaction-wrap {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: scroll;
    // @include respond-to("small") {
    //   display: none;
    // }
    .slider-wrapper {
      padding: 10px 0px;
      display: flex;
      justify-content: center;
      // width: 77%;
      margin: 0 auto;
      // @include respond-to("medium") {
      //   padding: 10px 20px;
      // }
      // @include respond-to("small") {
      //   padding: 0px 10px 10px;
      // }
      .carousel-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        .carousel-wrapper {
          display: flex;
          width: 100%;
          position: relative;
          .carousel-content-wrapper {
            overflow: hidden;
            margin: 0px auto;
            width: 80%;
            height: 100%;
            @include respond-to("ex_large_1600") {
              width: 70%;
            }
            @include respond-to("ex_large_max") {
              width: 80%;
            }
            @include respond-to("small") {
              width: 100%;
            }
            @include scrollcarousel;
            .carousel-content > * {
              // width: 100%;
              // flex-shrink: 1;
              // flex-grow: 1;
            }
            .carousel-content {
              display: flex;
              transition: all 250ms linear;
              -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
              scrollbar-width: none; /* hide scrollbar in Firefox */

              @include respond-to("ex_large_max") {
              }
              @include respond-to("medium_lg") {
              }
              @include respond-to("small") {
              }
              .show-2 > * {
                width: 50%;
              }

              .show-3 > * {
                width: calc(100% / 3);
              }

              .show-4 > * {
                width: calc(100% / 4);
              }
            }
          }
          .gradient-left {
            position: absolute;
            height: 100%;
            width: 20%;
            z-index: 1;
            background: rgb(255, 255, 255);
            background: -moz-linear-gradient(
              90deg,
              rgba(255, 255, 255, 1) 50%,
              rgba(255, 255, 255, 0) 100%
            );
            background: -webkit-linear-gradient(
              90deg,
              rgba(255, 255, 255, 1) 50%,
              rgba(255, 255, 255, 0) 100%
            );
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 1) 50%,
              rgba(255, 255, 255, 0) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
            @include respond-to("small") {
              width: 10%;
            }
          }
          .gradient-right {
            position: absolute;
            height: 100%;
            width: 20%;
            z-index: 1;
            right: 0;
            background: rgb(255, 255, 255);
            background: -moz-linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 50%
            );
            background: -webkit-linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 50%
            );
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 50%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
            @include respond-to("small") {
              width: 10%;
            }
          }
          .left-arrow {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            width: 48px;
            height: 48px;
            border-radius: 24px;
            background-color: white;
            // border: 1px solid #ddd;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
            left: 30px;
            cursor: pointer;
            @include respond-to("small") {
              display: none;
            }
          }
          .right-arrow {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            width: 48px;
            height: 48px;
            border-radius: 24px;
            background-color: white;
            // border: 1px solid #ddd;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
            right: 30px;
            cursor: pointer;
            @include respond-to("small") {
              display: none;
            }
          }
        }
      }
    }
  }
  .card-wrapper {
    display: flex;
    &.none-active {
      margin-right: -25px;
    }
    @include respond-to("ex_large") {
      margin-right: -20px;
    }
    @include respond-to("ex_large_max") {
      margin-right: -20px;
    }

    @include respond-to("medium_lg") {
      &.none-active {
        margin-bottom: 0px;
        margin-right: 0px;
      }
      &.active {
        // margin-right: -30px;
        margin-bottom: 5px;
        // margin-left: 8px;
      }
    }
    @include respond-to("medium") {
      &.none-active {
        //margin-bottom: 170px;
      }
      &.active {
        margin-right: 0px;
        margin-bottom: 5px;
        // margin-left: 8px;
      }
    }
    @include respond-to("exsmall_574") {
      // margin-bottom: 0px;
      // margin-right: 0px;
      &.none-active {
        margin-bottom: 0px;
      }
      &.active {
        // margin-bottom: -50px;
        // margin-right: 10px;
        // margin-left: 0px;
      }
    }
    @include respond-to("small") {
      &.none-active {
        margin-bottom: 0px;
      }
      &.active {
        margin-bottom: -50px;
        // margin-right: 0px;
      }
    }
    @include respond-to("exsmall_320") {
      &.active {
        // margin-bottom: -90px;
      }
    }

    .card {
      text-decoration: none;
      // width: 18%;
      background-color: $white-color;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      transition: transform 0.3s;
      text-align: center;
      // min-height: 320px;
      cursor: pointer;
      // padding: 0px 70px;
      // margin: 20px;
      transition-duration: 0.3s;
      z-index: 2;
      // width: 100px;

      padding: 0px 22px;
      margin: 15px;
      min-height: 328px;
      width: 196px;
      &.open {
        width: 248px;
      }
      @include respond-to("ex_large") {
        padding: 0px 22px;
        margin: 15px;
        min-height: 328px;
        width: 196px;
      }
      @include respond-to("ex_large_1600") {
        padding: 0px 22px;
        margin: 15px;
        min-height: 328px;
        width: 196px;
        &.open {
          width: 242px;
        }
      }
      @include respond-to("ex_large_max") {
        padding: 0px 22px;
        margin: 15px;
        min-height: 328px;
        width: 196px;
      }
      @include respond-to("medium_lg") {
        margin: 15px 22px;
        min-height: 302px;
        width: 176px;
        &.open {
          width: 226px;
          margin-bottom: 0px;
          // padding: 0px 60px;
          margin: 15px 10px;
        }
        &.none-active {
          margin: 15px 10px;
        }
      }
      @include respond-to("medium") {
        width: 146px;
        // padding: 0px 55px;
        // margin: 15px auto;
        // min-height: 50px;
        margin: 15px 12px;
        &.open {
          margin: 15px 12px;
          width: 190px;
        }
      }
      @include respond-to("small") {
        width: 160px;
        padding: 0px 18px;
        margin: 15px 14px;
        min-height: 306px;
        &.open {
          width: 200px;
          padding: 0px 18px;
        }
      }
      @include respond-to("exsmall_574") {
        // padding: 0 50px;
        // min-height: 292px;
      }
      @include respond-to("exsmall_320") {
        // padding: 0px 40px;
        // min-height: 235px;
        &.open {
          // margin-bottom: 100px;
        }
      }

      .card-top {
        display: flex;
        border: 1px solid $white-color;
        justify-content: center;
        align-items: center;
        padding: 0;
        // margin: 10px -45px;

        @include respond-to("ex_large") {
          padding: 0px;
        }
        @include respond-to("medium_lg") {
          padding: 0px 14px;
          // margin: 0px -50px;
          border: none;
        }
        @include respond-to("medium") {
          // padding: 0px 7px;
          // margin: 0px -50px;
          // border: none;
        }

        @include respond-to("small") {
          // padding: 0px 15px;
          // margin: 10px -40px;
          // border: none;
        }
        .date {
          font-size: 14px;
          font-weight: 500;
          color: $black-color;
          text-align: left;
          @include respond-to("medium_lg") {
            font-size: 12px;
          }
          @include respond-to("medium") {
            // font-size: 10px;
          }
          @include respond-to("small") {
            // font-size: 10px;
          }
        }
        .ukus-span {
          float: right;
          text-align: right;
          margin-top: 8px;
          //margin-left: 30%;
          @include respond-to("medium_lg") {
            margin-left: -5px;
          }
          @include respond-to("small") {
            //margin-left: -5px;
            //display: flex;
          }
          .uk {
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
            margin: 5px;
            @include respond-to("medium_lg") {
              //width: 50%;
            }
            @include respond-to("small") {
              // width: 50%;
            }
          }
          .uk2 {
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
            margin: 5px 5px 5px 0px;
            @include respond-to("medium_lg") {
              //width: 50%;
            }
            @include respond-to("small") {
              // width: 50%;
            }
          }
        }
      }

      .card-hr {
        align-items: center;
        border: solid 0.5px #d9d9d9;
      }

      .card-info {
        padding-bottom: 0px;
        // margin: 0px -60px;
        @include respond-to("medium_lg") {
          padding-bottom: 0;
          // margin: 0 auto;
          padding-top: 12px;
        }
        .image-container {
          // width: 200px;
          margin: 24px auto;

          .img1 {
            height: 50px;
            @include respond-to("medium_lg") {
              height: 40px;
            }
            @include respond-to("medium") {
              height: 38px;
              margin: 0 auto;
            }
            @include respond-to("small") {
              height: 40px;
            }
            @include respond-to("exsmall_320") {
            }
          }
          .img2 {
            height: 50px;
            @include respond-to("medium_lg") {
              height: 40px;
            }
            @include respond-to("medium") {
              height: 38px;
              margin: 0 auto;
            }
            @include respond-to("small") {
              height: 40px;
            }
            @include respond-to("exsmall_320") {
            }
          }
        }

        .aquired {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: $black-color;
          width: 70%;
          margin: 0px auto;
          @include respond-to("medium_lg") {
            font-size: 12px;
            width: 70%;
            margin: 0px auto;
          }
          @include respond-to("medium") {
            width: 80%;
          }
          @include respond-to("small") {
            width: 80%;
          }
        }
      }
    }
    .data-wrapper {
      display: none;

      &.open {
        z-index: 1;
        //visibility: hidden;
        display: block;
        transition-duration: 0.3s;
        width: 55%;
        height: 350px;
        visibility: visible;
        opacity: 1;
        margin-right: 50px;
        @include respond-to("ex_large") {
          margin-right: 30px;
          &.open {
            margin-right: 0px;
          }
        }
        @include respond-to("ex_large_max") {
          margin-right: 30px;
          &.open {
            margin-right: 0px;
          }
        }
        @include respond-to("medium_lg") {
          // margin-right: 30px;
          height: 270px;
        }
        @include respond-to("small") {
          //display: none;
          &.open {
            // width: 95px;
            margin-right: 0px;
          }
        }
        @include respond-to("exsmall_574") {
          margin-right: 0px;
        }
      }
      .cardData {
        text-decoration: none;
        width: 145px;
        background-color: $white-color;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        z-index: 1;
        transition-duration: 0.3s;
        padding: 12px 18px 14px 35px;
        min-height: 302px;
        margin: 15px -24px 0px -30px;
        @include respond-to("ex_large") {
          padding: 12px 18px 14px 35px;
          min-height: 302px;
          margin: 15px -24px 0px -30px;
        }
        @include respond-to("ex_large_max") {
          padding: 14px 20px 14px 45px;
          width: 145px;
          margin: 15px -24px 0px -30px;
        }
        @include respond-to("medium_lg") {
          width: 120px;
          padding: 22px 10px 22px 48px;
          min-height: 260px;
          margin: 15px -24px 0px -30px;
        }
        @include respond-to("medium") {
          margin: 15px -24px 0px -30px;
          padding: 20px 10px 22px 36px;
        }
        @include respond-to("small") {
          width: 125px;
          padding: 10px 10px 14px 35px;
          min-height: 283px;
          margin: 15px -24px 0px -30px;
        }
        @include respond-to("exsmall_574") {
          // padding: 10px 10px 10px 20px;
        }
        @include respond-to("exsmall_320") {
          // padding: 12px 5px 18px 15px;
        }

        .cardTitle {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: $black-color;
          margin-top: 10px;
          @include respond-to("ex_large") {
            margin-top: 15px;
          }
          @include respond-to("ex_large_max") {
            margin-top: 15px;
          }
          @include respond-to("medium_lg") {
            font-size: 10px;
            margin-top: 14px;
          }
        }
        .cardDetail {
          font-size: 12px;
          font-weight: 300;
          text-align: left;
          color: $black-color;
          margin-bottom: 20px;
          @include respond-to("ex_large") {
            margin-bottom: 22px;
          }
          @include respond-to("ex_large_max") {
            margin-bottom: 22px;
          }
          @include respond-to("medium_lg") {
            margin-bottom: 13px;
            font-size: 10px;
          }
        }
      }
    }
  }
}
