.requet-form-content {
  background-color: $white-color;
  width: 50%;
  position: relative;
  margin: 80px auto;
  z-index: 999;
  box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
  padding: 32px 24px;
  @include respond-to("medium_lg") {
    width: 70%;
  }
  @include respond-to("medium") {
    width: 75%;
  }
  @include respond-to("small") {
    width: 80%;
    margin: 60px auto;
  }
  .modal-header {
    display: flex;
    justify-content: flex-end;
    .modal-close-button {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      opacity: 0.3;
      cursor: pointer;
      border: none;
      background-color: $white-color;
    }
    .button-default {
      background: #247ba0;
      color: #fff;
    }
  }

  .modal-footer {
    p {
      font-size: 12px;
      a {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }
}

.subscribe-form-content {
  background-color: $white-color;
  width: 60%;
  position: relative;
  margin: 0px auto;
  // z-index: 999;
  box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
  padding: 32px 24px;
  @include respond-to("medium_lg") {
    width: 70%;
  }
  @include respond-to("medium") {
    width: 75%;
  }
  @include respond-to("small") {
    width: 80%;
    margin: 0px auto;
  }
  .modal-header {
    display: flex;
    justify-content: flex-end;
    .modal-close-button {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      opacity: 0.3;
      cursor: pointer;
      border: none;
      background-color: $white-color;
    }
    .button-default {
      background: #247ba0;
      color: #fff;
    }
  }

  .modal-footer {
    p {
      font-size: 12px;
      a {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }
}
