@mixin scrollcarousel {
  &::-webkit-scrollbar {
    display: none;
  }
}
.about-us-wrapper {
  .about-story-section {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-image: linear-gradient(to bottom, #fff, #f3f3f3);
    box-shadow: 0 -5px 30px 0 rgba(160, 106, 106, 0.1);
    border-bottom: none;
    width: 70%;
    margin: 0 auto;
    padding: 60px 40px;
    @include respond-to("small") {
      //margin-top: 300px;
      padding: 60px 40px 90px;
    }
    .about-description {
      p {
        width: 83%;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        color: $black-color;
        @include respond-to("medium") {
          width: 85%;
        }
        @include respond-to("small") {
          font-size: 12px;
          width: 90%;
        }
      }
    }
    .middle-divider {
      margin: 40px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond-to("small") {
        margin: 70px 0px;
      }
      span {
        height: 2px;
        width: 25%;
        background-color: $primary-color;
        margin: auto 0px;
        @include respond-to("small") {
          width: 55%;
          height: 1px;
        }
      }
    }
  }
  .about-map-section {
    margin: 60px auto;
    .about-description {
      p {
        width: 83%;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        color: $black-color;
        @include respond-to("medium") {
          width: 85%;
        }
        @include respond-to("small") {
          font-size: 12px;
          width: 90%;
          // margin-bottom: 40px;
        }
      }
    }
    .map-container-inner {
      // flex: 1 1 50%;
      text-align: center;
      width: 50%;
      margin: 60px auto;

      @include respond-to("ex_large") {
        //   top: 36%;
        //   left: 2%;
        width: 50%;
        height: 356.275px;
        margin: 180px auto;
      }
      @include respond-to("ex_large_max") {
        width: 50%;
        height: 356.275px;
        margin: 60px auto;
      }
      @include respond-to("medium") {
        width: 70%;
        height: 300px;
        margin: 95px auto;
      }
      @include respond-to("small") {
        width: 80%;
        height: 200px;
        margin: 95px auto;
      }
      // @include respond-to("medium_lg") {
      //   width: 475px;
      //   top: 27%;
      // }
      // @include respond-to("medium") {
      //   width: 372px;
      //   top: 24%;
      // }
      // @include respond-to("small") {
      //   top: 62%;
      //   left: 12%;
      //   width: 350px;
      //   margin: 62px auto;
      // }
      // @include respond-to("exsmall_574") {
      //   top: 62%;
      //   left: 12%;
      //   width: 368px;
      //   margin: 62px auto;
      // }
      // @include respond-to("exsmall") {
      //   top: 62%;
      //   left: 12%;
      //   width: 346px;
      //   margin: 62px auto;
      // }
      // @include respond-to("exsmall_320") {
      //   width: 296px;
      //   margin: 100px auto 62px;
      // }
      .country-select-tab {
        display: flex;
        // width: 100%;
        // margin: 0px 42px;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 6px;
        background-color: $white-color;
        // background-color: $white-color;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
        z-index: 2;
        @include respond-to("ex_large_max") {
          width: 97%;
        }
        .tab-card {
          transition-duration: 0.4s;
          transition-delay: 0.1s;
          background-color: $white-color;
          padding: 12px 6px;
          width: 17%;
          color: #848484;
          text-align: center;
          border-radius: 6px;
          border: solid 2px transparent;
          cursor: pointer;

          &.active {
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
            transform: scale(1.05);
            border: solid 2px;
            animation: fade-in-keyframes 0.2s ease-in-out;
            @keyframes fade-in-keyframes {
              from {
                opacity: 0;
                transform: scale(0.8);
                transform: translateY(0px);
              }
              to {
                opacity: 1;
                transform: scale(1);
                transform: translateY(-14px);
              }
            }

            //margin-bottom: 24px;
            transform: translateY(-14px);
            color: $primary-color;
          }
          &.none-active {
            transition-duration: 0.4s;
            transition-delay: 0.1s;
            background-color: $white-color;
            padding: 12px 6px;
            width: 17%;
            color: #848484;
            text-align: center;
            border-radius: 6px;
            border: solid 2px transparent;
            cursor: pointer;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            @include respond-to("ex_large_max") {
              font-size: 12px;
            }
            @include respond-to("small") {
              font-size: 8px;
            }
          }
        }
      }
      .sub-heading {
        margin: 24px auto;
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 600;
        @include respond-to("ex_large") {
          display: none;
        }
        @include respond-to("small") {
          display: none;
        }
        a {
          color: #484848;
          text-decoration: none;
          &:hover {
            color: $primary-color;
            font-weight: 600px;
          }

          &:active {
            color: black;
            font-weight: 600px;
          }
        }
      }
    }
  }
  .about-image-slider-wrapper {
    // display: flex;
    margin: auto 0px;
    padding: 60px 0px;
    overflow-x: scroll;
    @include respond-to("medium") {
      padding: 20px 0px;
    }
    @include respond-to("small") {
      padding: 20px 0px;
    }
    .carousel-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      .carousel-wrapper {
        display: flex;
        width: 100%;
        position: relative;
        .carousel-content-wrapper {
          overflow: hidden;
          width: 100%;
          height: 100%;
          @include scrollcarousel;
          .carousel-content > * {
            width: 100%;
            flex-shrink: 1;
            flex-grow: 1;
          }
          .carousel-content {
            display: flex;
            transition: all 250ms linear;
            -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
            scrollbar-width: none; /* hide scrollbar in Firefox */

            .show-2 > * {
              width: 50%;
            }

            .show-3 > * {
              width: calc(100% / 3);
            }

            .show-4 > * {
              width: calc(100% / 4);
            }
          }
        }
        .left-arrow {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);
          width: 48px;
          height: 48px;
          border-radius: 24px;
          background-color: white;
          border: 1px solid #ddd;
          left: 24px;
        }
        .right-arrow {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);
          width: 48px;
          height: 48px;
          border-radius: 24px;
          background-color: white;
          border: 1px solid #ddd;
          right: 24px;
        }
      }
    }
  }
}
