//===============================================================
//================disclosure inner page styles=============
//===============================================================

.disclosure_page {
  width: 100%;
  .disclaimers_and_notices {
    width: 100%;
    margin: 25px auto 0px;
    padding-bottom: 50px;
    @include respond-to("small") {
      margin: 20px auto 0px;
    }
    .para {
      width: 70%;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 500px;
      line-height: 28px;
      @include respond-to("small") {
        font-size: 16px;
      }
      ul {
        padding: 20px 55px;
        @include respond-to("small") {
          padding: 15px 30px;
        }
        li {
          line-height: 35px;
        }
      }
      a {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }
  .middle-divider {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to("small") {
      margin: 70px 0px;
    }
    span {
      height: 2px;
      width: 25%;
      background-color: $primary-color;
      margin: auto 0px;
      @include respond-to("small") {
        width: 55%;
        height: 1px;
      }
    }
  }
}
