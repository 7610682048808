@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
.representative_wrapper {
  //   padding-top: 150px;
  // padding-bottom: 200px;
  //margin-bottom: 60px;
  box-sizing: border-box;
  @include respond-to("small") {
    // padding-top: 0px;
    //padding-bottom: 20px;
  }
  .representative_slider {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: scroll;

    // make scroll behavior smooth only when mouseOver
    // else it will stop the auto scrolling function
    &:hover {
      scroll-behavior: smooth;
    }

    @include scrollbars(0px, $black-color, $black-color);

    .representative_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
     // height: 510px;
     height: 400px;

      animation-name: slide;
      animation-play-state: paused;
      animation-duration: 15s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear;

      &:hover {
        animation-play-state: paused;
      }
      &.pause {
        animation-play-state: paused;
      }

      // ===============================================
      // ==== Representative Buyer Item ================
      // ===============================================

      .Item_wrapper {
        .buyerItemClose {
          width: 150px;
          height: 150px;
          border-radius: 16px;
          // box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
          background-color: $white-color;
          margin: 10px;
          cursor: pointer;
          transition-duration: 0.3s;
          position: absolute;
          opacity: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: solid 2px $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            transition-duration: 0.8s;
            transition-delay: 0.15s;
            .bar {
              display: block;
              width: 25%;
              height: 25%;
              border-left: solid 2px $primary-color;
              border-bottom: solid 2px $primary-color;
              transform: translateX(-2px) translateY(0px) rotate(-135deg);
            }
          }

          &.opened {
            opacity: 1;

            .icon {
              transform: rotate(180deg);
            }
          }
        }
        .buyerItem {
          width: 150px;
          height: 150px;
          object-fit: contain;
          border-radius: 16px;
          box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.06);
          background-color: $white-color;
          margin: 10px;
          //cursor: pointer;
          //transition-duration: 0.3s;

          img {
            width: calc(100% - 36px);
            margin: 18px;
            // filter: grayscale(1);
            // opacity: 0.5;
            //transition-duration: 0.3s;
          }

          &:hover {
            //border: 3px solid #95c420;
            //box-shadow: inset 0px 0px 0px 3px $primary-color;
            box-sizing: border-box;

            img {
              filter: grayscale(0);
              opacity: 1;
            }
          }
          // &.opened {
          //   margin-right: 380px;
          //   transform: translateX(170px);
          //   img {
          //     filter: grayscale(0);
          //     opacity: 1;
          //   }
          // }
        }

        .dataCard_wrapper {
          position: absolute;
          height: 0px;
          width: 0px;
          overflow: hidden;
          transform: translateX(210px) translateY(20px);
          transition-duration: 0.3s;

          &.opened {
            width: 324px;
            height: 490px;
            .dataCard {
              .title_section {
                opacity: 1;
                transition-delay: 0.3s;
              }
              .desc_section {
                opacity: 1;
                transition-delay: 0.3s;
              }
              .name_section {
                opacity: 1;
                transition-delay: 0.3s;
              }
            }
          }

          .dataCard {
            margin: 10px;
            width: calc(100% - 20px);
            height: calc(490px - 20px);
            border-radius: 16px;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
            background-color: $white-color;
            box-sizing: border-box;
            padding: 15px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title_section {
              transition-duration: 0.3;
              opacity: 0;
              margin-left: 110px;
              h3 {
                color: $dark;
                font-family: Poppins;
                font-size: 24px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                margin-bottom: 15px;
              }

              a {
                display: block;
                height: 36px;
                border-radius: 8px;
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
                text-decoration: none;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: $white-color;
                padding: 8px;
                box-sizing: border-box;
              }
            }

            .desc_section {
              transition-duration: 0.3;
              opacity: 0;
              span {
                font-family: Poppins;
                font-size: 52px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: $dark;
                opacity: 0.4;

                &:nth-child(1) {
                  display: block;
                  transform: rotateX(180deg);
                  margin-left: 25px;
                }

                &:nth-child(3) {
                  width: 100%;
                  text-align: right;
                  float: right;
                }
              }
              p {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: justify;
                color: $black-color;
              }
            }

            .name_section {
              transition-duration: 0.3;
              opacity: 0;
              text-align: right;

              h4 {
                &:nth-child(1) {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: right;
                  color: $black-color;
                }
                &:nth-child(2) {
                  opacity: 0.6;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: right;
                  color: $black-color;
                }
                &:nth-child(3) {
                  opacity: 0.6;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 300;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: right;
                  color: $black-color;
                }
              }
            }
          }
        }

        // &:nth-child(3n - 2) {
        // }

        &:nth-child(3n - 1) {
          .buyerItem {
            &.opened {
              transform: translateY(-170px) translateX(170px);
            }
          }
          .dataCard_wrapper {
            &.opened {
              transform: translateY(-150px) translateX(210px);
            }
          }
        }

        &:nth-child(3n) {
          .buyerItem {
            &.opened {
              transform: translateY(-340px) translateX(170px);
            }
          }
          .dataCard_wrapper {
            &.opened {
              transform: translateY(-320px) translateX(210px);
            }
          }
        }
      }
    }
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% + 150vh));
  }
}
