// ===============================================
// ====== Home page About section styles =========
// ===============================================
@import "common";
.about-page {
  //background-image: linear-gradient(180deg, #ebecec, #edeeee);
  width: 100%;
  padding: 0px;
  background-image: linear-gradient(360deg, #f6f7f7, #ebecec);

  @include respond-to("exsmall_574") {
    padding: 0px;
  }
  @include respond-to("exsmall_320") {
    padding: 0px;
  }
  .about-wrapper {
    position: relative;
    // top: -100px;
    margin: 0 auto;
    width: 100%;

    // .about-back-img {
    //   width: 100%;
    //   object-fit: cover;
    //   margin-top: -450px;
    //   background-size: contain;
    //   margin-bottom: 0px;
    //   z-index: -3;
    //   // @include line-shadow;
    //   @include respond-to("medium") {
    //     margin-bottom: 0px;
    //   }
    //   @include respond-to("small") {
    //     display: none;
    //   }
    // }

    .about-back-img {
      width: 100%;
      object-fit: cover;
      //margin-top: -450px;
      background-size: contain;
      margin-bottom: -357px;
      z-index: -3;
      // @include line-shadow;
      @include respond-to("ex_large_1600") {
        margin-bottom: -370px;
      }
      @include respond-to("ex_large_max") {
        margin-bottom: -333px;
      }
      @include respond-to("large_1300") {
        margin-bottom: -280px;
      }
      @include respond-to("medium_lg") {
        margin-bottom: -245px;
      }
      @include respond-to("medium") {
        margin-bottom: -240px;
      }
      @include respond-to("small") {
        display: none;
      }
    }

    .about2-back-img {
      width: 100%;
      object-fit: cover;
      background-size: contain;
      z-index: -3;
      margin-top: 50px;
      // @include line-shadow;
      @include respond-to("ex_large_1600") {
        margin-top: 120px;
      }
      @include respond-to("ex_large_max") {
        margin-top: 140px;
      }
      @include respond-to("large") {
        margin-top: 50px;
      }
      @include respond-to("medium_lg") {
        margin-top: 140px;
      }
      @include respond-to("small") {
        display: none;
      }
    }

    z-index: 99;
    @include respond-to("small") {
      width: 90%;
    }

    .first-section {
      width: 77%;
      margin: 0 auto 50px;
      //margin-top: -1000px;
      @include respond-to("ex_large_max") {
        // margin-bottom: 20px;
      }
      @include respond-to("medium") {
        width: 85%;
        // margin-bottom: 0;
      }
      @include respond-to("small") {
        margin-bottom: 30px;
        box-shadow: none;
        background-image: none;
        width: 98%;
      }
    }
    .about-details {
      text-align: center;
      padding: 60px 0px 50px 0px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-image: linear-gradient(to bottom, $white-color, #f3f3f3);
      box-shadow: 0 -5px 30px 0 rgba(160, 106, 106, 0.1);
      border-bottom: none;
      @include respond-to("medium_lg") {
        padding: 40px 0px 30px 0px;
      }
      @include respond-to("medium") {
        margin-bottom: 30px;
      }
      @include respond-to("small") {
        border-bottom: none;
        padding: 30px 0px 10px 0px;
      }
      .about-logo {
        text-align: center;
        width: 55px;
        margin: 0 auto;
        margin-top: 70px;
        margin-bottom: 40px;
      }
      .about-title {
        // margin-top: -70px;
        @include respond-to("small") {
          margin-top: 0px;
        }
        h1 {
          font-size: 18px;
          margin: 0 auto;
          font-weight: 500;
          text-align: center;
          color: $black-color;
          width: 65%;
          margin-bottom: 36px;
          @include respond-to("medium") {
            width: 70%;
            margin-top: 20px;
          }
          @include respond-to("exsmall_574") {
            margin-top: 0px;
          }
          @include respond-to("small") {
            font-size: 14px;
            width: 75%;
          }
        }
      }
      .about-description {
        p {
          width: 83%;
          margin: 0 auto;
          font-size: 16px;
          font-weight: 300;
          text-align: justify;
          color: $black-color;
          margin-bottom: 40px;
          @include respond-to("large_1300") {
            margin-bottom: 40px;
          }
          @include respond-to("medium") {
            width: 85%;
          }
          @include respond-to("small") {
            font-size: 12px;
            width: 81%;
            margin-bottom: 30px;
          }
        }
      }
      .about-description1 {
        p {
          width: 83%;
          margin: 0 auto;
          font-size: 16px;
          font-weight: 300;
          text-align: center;
          color: $black-color;
          margin-bottom: 40px;
          @include respond-to("large_1300") {
            margin-bottom: 40px;
          }
          @include respond-to("medium") {
            width: 85%;
          }
          @include respond-to("small") {
            font-size: 12px;
            width: 90%;
            margin-bottom: 30px;
          }
          @include respond-to("exsmall_320") {
            width: 80%;
          }
        }
      }
    }
  }
}
