// =================================================
// ========== sector expertise styles ===============
// =================================================
.arrow-cards-wrapper {
  background-image: linear-gradient(360deg, #ebecec, #ebecec);

  .arrow-main-container {
    background-image: url("https://d3r4zj1odb19qx.cloudfront.net/assets/line1.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    object-fit: contain;

    @include respond-to("small") {
      background-image: none;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      object-fit: contain;
    }
    .content-setion {
      width: 77%;
      margin: 0 auto;
      @include respond-to("ex_large_max") {
      }
      @include respond-to("medium") {
        width: 85%;
      }
      @include respond-to("small") {
        width: 90%;
      }
    }
  }
}
.about-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 85%;
  padding: 50px 0px 50px;
  @include polygonShadow;
  @include respond-to("ex_large_1600") {
    padding: 50px 0px 50px;
  }
  @include respond-to("ex_large_max") {
    // padding: 0px 0px 50px;
  }
  @include respond-to("large_1300") {
    // padding: 20px 0px 50px;
  }
  @include respond-to("medium_lg") {
    width: 100%;
    // padding: 0 0 40px;
  }
  @include respond-to("medium") {
    width: 91%;
  }
  @include respond-to("small") {
    // width: 85%;
    padding: 0 0 20px;
  }
  @include respond-to("exsmall_320") {
    width: 88%;
  }

  .chevron-main {
    margin: 15px;
    // padding-bottom: 20px;
    // border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 108px 0;
    width: 60%;
    z-index: 3;
    box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
    background-color: $white-color;
    min-height: 220px;
    // transition: 0.2s ease-in-out;
    margin-left: 90px;
    clip-path: polygon(59% 0%, 100% 50%, 59% 100%, 0% 100%, 28% 50%, 0% 0%);
    &:hover {
      transform: scale(1.05);
      transition: 0.2s ease-in-out;
    }
    @include respond-to("ex_large_1600") {
      padding: 68px 0;
      width: 70%;
      margin-left: 72px;
      clip-path: polygon(63% 0%, 100% 50%, 63% 100%, 0% 100%, 28% 50%, 0% 0%);
    }
    @include respond-to("ex_large_max") {
      // padding: 40px 20px;
      margin-left: 60px;
    }
    @include respond-to("large_1300") {
      // padding: 66px 0px;
      margin-left: 48px;
      // clip-path: polygon(63% 0%, 100% 50%, 63% 100%, 0% 100%, 28% 50%, 0% 0%);
    }
    @include respond-to("medium_lg") {
      padding: 68px 0px;
      // margin-left: 75px;
    }
    @include respond-to("medium") {
      margin-left: -4%;
      clip-path: polygon(63% 0%, 100% 50%, 63% 100%, 0% 100%, 28% 50%, 0% 0%);
      padding: 65px 0px;
      &:hover {
        transform: scale(1);
        transition: 0.2s ease-in-out;
      }
    }
    @include respond-to("small") {
      padding: 35px 0px;

      clip-path: polygon(68% 0%, 100% 50%, 68% 100%, 0% 100%, 28% 50%, 0% 0%);
    }
    @include respond-to("exsmall_574") {
      width: 70%;
      margin: 2px;
      margin-left: 0%;
      clip-path: polygon(63% 0%, 100% 50%, 63% 100%, 0% 100%, 28% 50%, 0% 0%);
      padding: 59px 0px;
      min-height: 100px;
    }
    @include respond-to("exsmall") {
      width: 70%;
      margin: 12px;
      margin-left: -6%;
      clip-path: polygon(66% 0%, 100% 50%, 66% 100%, 0% 100%, 30% 50%, 0% 0%);
      padding: 51px 0px;
    }
    @include respond-to("exsmall_320") {
      // clip-path: polygon(54% 0%, 100% 50%, 54% 100%, 0% 100%, 30% 50%, 0% 0%);
    }

    .card-inner {
      margin-left: 10%;
      @include respond-to("small") {
        margin-left: 23%;
      }
      @include respond-to("exsmall") {
        margin-left: 19%;
      }

      .card-main {
        padding: 12px 0px;
        .percentage {
          font-size: 48px;
          font-weight: bold;
          text-align: center;
          color: #f37021;
          line-height: 0.69;
          // margin-left: 23%;
          @include respond-to("ex_large_1600") {
          }
          @include respond-to("ex_large_max") {
          }
          @include respond-to("large_1300") {
          }
          @include respond-to("medium_lg") {
            font-size: 40px;
          }
          @include respond-to("medium") {
            font-size: 40px;
            // margin-left: 12px;
          }
          @include respond-to("small") {
            font-size: 22px;
            line-height: 0;
          }
        }
      }
      .card-title {
        padding: 12px 0px;
        .title-h3 {
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          color: gray;
          line-height: 1.38;
          //line-height: 0;
          // margin-left: 18%;
          @include respond-to("ex_large_1600") {
            //margin-left: 12px;
          }
          @include respond-to("ex_large_max") {
            //margin-left: 12px;
          }
          @include respond-to("large_1300") {
            //@debug: 40%;
          }
          @include respond-to("medium_lg") {
            font-size: 20px;
            // margin-left: 12px;
          }
          @include respond-to("medium") {
            font-size: 16px;
            // margin: 15px 5px;
            // margin-left: 34%;
          }
          @include respond-to("small") {
            font-size: 14px;
            // margin-left: 45px;
            line-height: 0;
          }

          @include respond-to("exsmall_320") {
            font-size: 12px;
          }
        }
      }
      .card-details {
        padding: 12px 0px;
        // padding: 0 75px;
        //margin: 0 auto;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        color: gray;
        // margin-left: 7%;
        @include respond-to("ex_large_1600") {
          //margin-left: 12px;
        }
        @include respond-to("ex_large_max") {
          //margin-left: 12px;
        }
        @include respond-to("large_1300") {
          //margin-left: 12%;
        }
        @include respond-to("medium_lg") {
          //margin-left: 12px;
        }

        @include respond-to("medium") {
          font-size: 14px;
          font-weight: 300;
          // padding: 0 2px;
          // margin-left: 20px;
        }
        @include respond-to("small") {
          font-size: 10px;
          font-weight: 300;
          //padding: 0 15px;
          line-height: 0;
        }
        @include respond-to("exsmall_320") {
          // font-size: 12px;
        }
      }
    }
  }
  .chevron-right-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 40%;
    padding: 10px 0;
    @include respond-to("ex_large_1600") {
      //margin: 0 10px;
    }
    @include respond-to("ex_large_max") {
      //margin: 0 20px;
    }
    @include respond-to("large_1300") {
      //margin: 0 20px;
    }
    // @include respond-to("medium") {
    //   width: 91%;
    // }
    @include respond-to("small") {
      margin: 0;

      padding: 25px 0;
    }
    @include respond-to("exsmall") {
      width: 30%;
    }
    @include respond-to("exsmall_320") {
      // width: 55%;
    }

    .chevron-one {
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      z-index: 3;
      box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
      background-color: $white-color;

      &:hover {
        transform: scale(1.05);
        transition: 0.2s ease-in-out;
      }
      padding: 24px 14px;
      @include respond-to("ex_large_1600") {
        padding: 14px 14px;
      }

      @include respond-to("medium_lg") {
        padding: 14px 14px;
      }
      @include respond-to("small") {
      }
      @include respond-to("exsmall_574") {
        padding: 8px 16px;
      }
      @include respond-to("exsmall") {
        padding: 8px 22px;
      }
      @include respond-to("exsmall_320") {
        // padding: 8px 8px;
      }
      .card-title {
        .title-h3 {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: hsl(23, 90%, 54%);
          line-height: 1.38;
          @include respond-to("small") {
            font-size: 12px;
          }
          @include respond-to("exsmall_574") {
            font-size: 10px;
          }
          @include respond-to("exsmall") {
            font-size: 8px;
          }
        }
      }
      .card-details {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: gray;

        @include respond-to("small") {
          font-size: 14px;
        }
        @include respond-to("exsmall_574") {
          font-size: 12px;
        }
        @include respond-to("exsmall") {
          font-size: 10px;
        }
      }

      &:nth-child(1) {
        margin-left: -100%;
        clip-path: polygon(75% 0%, 100% 100%, 25% 100%, 0% 0%);

        @include respond-to("ex_large_1600") {
          // margin-left: -95%;
        }
        @include respond-to("ex_large_max") {
        }
        @include respond-to("large_1300") {
          // margin-left: -90%;
        }
        @include respond-to("medium") {
          // padding: 0px 35px;
          margin-left: -76%;
        }
        @include respond-to("small") {
          clip-path: polygon(78% 0%, 100% 100%, 22% 100%, 0% 0%);
          // padding: 22px 22px;
          margin: 5px;
          margin-left: -57%;
          &:hover {
            transform: scale(1);
            transition: 0.2s ease-in-out;
          }
        }
        @include respond-to("exsmall_574") {
          clip-path: polygon(78% 0%, 100% 100%, 22% 100%, 0% 0%);
          // padding: 0 22px;
          margin: 5px;
          margin-left: -62%;
        }
        @include respond-to("exsmall") {
          clip-path: polygon(78% 0%, 100% 100%, 22% 100%, 0% 0%);
          margin: 5px;
          margin-left: -78%;
        }
        @include respond-to("exsmall_320") {
          // margin-left: -62%;
        }
      }
      &:nth-child(2) {
        margin-left: -34%;
        clip-path: polygon(75% 0%, 100% 100%, 25% 100%, 0% 0%);

        @include respond-to("ex_large_1600") {
          // margin-left: -40%;
        }
        @include respond-to("ex_large_max") {
        }
        @include respond-to("large_1300") {
          // margin-left: -30%;
        }
        @include respond-to("medium") {
          // padding: 0px 35px;
          margin-left: -7%;
        }
        @include respond-to("small") {
          clip-path: polygon(78% 0%, 100% 100%, 22% 100%, 0% 0%);
          // padding: 22px 22px;
          margin: 5px;
          margin-left: 1%;
          &:hover {
            transform: scale(1);
            transition: 0.2s ease-in-out;
          }
        }
        @include respond-to("exsmall_574") {
          clip-path: polygon(78% 0%, 100% 100%, 22% 100%, 0% 0%);
          // padding: 0 22px;
          margin: 5px;
          margin-left: 2%;
        }
        @include respond-to("exsmall") {
          clip-path: polygon(78% 0%, 100% 100%, 22% 100%, 0% 0%);
          margin: 5px;
          margin-left: 1%;
        }
        @include respond-to("exsmall_320") {
        }
      }
      &:nth-child(3) {
        margin-left: -34%;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

        @include respond-to("ex_large_1600") {
          // margin-left: -41%;
        }
        @include respond-to("ex_large_max") {
          // padding: 10px 40px;
          // width: 32%;
        }
        @include respond-to("large_1300") {
          // margin-left: -30%;
        }
        @include respond-to("medium") {
          // padding: 0px 35px;
          margin-left: -7%;
        }
        @include respond-to("small") {
          clip-path: polygon(22% 0%, 100% 0%, 78% 100%, 0% 100%);
          // padding: 22px 22px;
          margin: 5px;
          margin-left: 1%;
          &:hover {
            transform: scale(1);
            transition: 0.2s ease-in-out;
          }
        }
        @include respond-to("exsmall_574") {
          clip-path: polygon(22% 0%, 100% 0%, 78% 100%, 0% 100%);
          // padding: 0 22px;
          margin: 5px;
          margin-left: 2%;
        }
        @include respond-to("exsmall") {
          margin: 5px;
          margin-left: 1%;
          clip-path: polygon(22% 0%, 100% 0%, 78% 100%, 0% 100%);
        }
        @include respond-to("exsmall_320") {
        }
      }
      &:nth-child(4) {
        margin-left: -100%;

        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

        @include respond-to("ex_large_1600") {
          // margin-left: -95%;
        }
        @include respond-to("ex_large_max") {
        }
        @include respond-to("large_1300") {
          // margin-left: -85%;
        }
        @include respond-to("medium") {
          // padding: 0px 35px;
          margin-left: -76%;
        }
        @include respond-to("small") {
          clip-path: polygon(22% 0%, 100% 0%, 78% 100%, 0% 100%);
          // padding: 22px 22px;
          margin: 5px;
          margin-left: -57%;
          &:hover {
            transform: scale(1);
          }
        }
        @include respond-to("exsmall_574") {
          clip-path: polygon(22% 0%, 100% 0%, 78% 100%, 0% 100%);
          // padding: 0 22px;
          margin: 5px;
          margin-left: -62%;
        }
        @include respond-to("exsmall") {
          clip-path: polygon(22% 0%, 100% 0%, 78% 100%, 0% 100%);
          margin: 5px;
          margin-left: -78%;
        }
        @include respond-to("exsmall_320") {
          // margin-left: -62%;
        }
      }
    }
  }
}
