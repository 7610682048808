// =========================================
// ====== Contact styles =========
// =========================================
@import "variables";

.contact-page {
  .contact-header-section {
    img {
      width: 100%;
      object-fit: cover;
      display: block;
      //min-height: 90px;
      height: 62vh;
    }
  }

  .contact-card-wrapper {
    margin-top: -170px;
    margin-bottom: 50px;
    .contact-card {
      position: relative;
      // top: -170px;
      margin: auto 215px;
      padding: 97.7px 0;
      border-radius: 12px;
      z-index: 99;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;
      @include respond-to("medium_lg") {
        margin: auto;
        width: 80%;
      }
      @include respond-to("medium") {
        margin: auto;
        width: 80%;
      }
      @include respond-to("small") {
        // top: -150px;
        margin: 0 auto;
        padding: 50px 0px;
        width: 90%;
        // margin-bottom: 10px;
      }
      .contact-text {
        font-size: 36px;
        font-weight: normal;
      }
      .card-wrapper {
        display: flex;
        justify-content: center;
        width: 90%;
        //align-items: center;
        margin: -12px auto 10px;
        @include respond-to("medium") {
          display: block;
        }
        @include respond-to("small") {
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
        }
        .card {
          margin: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px;
          transform: scale(1);
          transition: 0.2s ease-in-out;
          border-radius: 12px;
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
          background-color: #fff;
          width: 20%;
          min-height: 190px;
          text-decoration: none;
          @include respond-to("medium") {
            width: 80%;
            box-shadow: none;
          }
          @include respond-to("small") {
            width: 80%;
            padding: 25px;
            min-height: 230px;
            margin: 8px;
            box-shadow: none;
          }
          .icon {
            img {
            }
          }
          .card-desc {
            p {
              text-align: center;
              line-height: 2.07;
              font-size: 14px;
              font-weight: 300;
              color: #1c1c1c;
            }
          }
        }
        .line-mob-only {
          display: none;
          @include respond-to("medium") {
            display: block;
            height: 1px;
            width: 80%;
            background: $apple;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .form-section {
    width: 100%;
    position: relative;
    background-image: url("https://d3r4zj1odb19qx.cloudfront.net/assets/form_background_arrow.webp");
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    background-position: center;
    @include respond-to("ex_large_1600") {
      background-size: contain;
    }
    @include respond-to("medium") {
      background-image: none;
    }

    .contact-back-img {
      width: 100%;
      object-fit: cover;
      display: block;
      margin-top: -120px;
    }
    .form-layout {
      // position: absolute;
      //margin-top: -670px;
      width: 100%;
      top: 3%;
      @include respond-to("small") {
        // padding-bottom: 70px;
      }

      .form-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 30%;
        margin: 0 auto;
        padding: 95px auto;
        .form-title-h2 {
          text-align: center;
          color: #222222;
          font-size: 36px;
          font-weight: normal;
        }
        .form-title-p {
          font-size: 36px;
          font-weight: 600;
        }
      }

      .form {
        width: 40%;
        border-radius: 8px;
        box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
        background-color: #fff;
        margin: 0px auto;
        padding: 20px 50px 50px;
        @include respond-to("medium") {
          width: 70%;
        }
        @include respond-to("small") {
          padding: 20px;
          width: 80%;
          margin-top: 3px;
        }
        .full-name {
          display: flex;
          @include respond-to("small") {
            display: flex;
            flex-flow: column;
          }
          .name {
            width: 50%;
            @include respond-to("small") {
              width: 97%;
            }
            input[type="text"] {
              width: 100%;
              color: $black-color;
              padding: 12px 0px;
              margin: 8px 0;
              box-sizing: border-box;
              border: none;
              border-bottom: 2px solid #e5e5e5;
              font-size: 14px;
              font-weight: 300;
              @include respond-to("small") {
                width: 97%;
              }
            }

            input:focus {
              width: 100%;
              color: $black-color;
              padding: 12px 0px;
              margin: 8px 0;
              outline: none;
              border-bottom: 2px solid #e5e5e5;
              font-size: 14px;
              font-weight: 300;
              opacity: 0.8;
            }
          }
        }
        input[type="text"] {
          width: 100%;
          color: $black-color;
          padding: 12px 0px;
          margin: 8px 0;
          box-sizing: border-box;
          border: none;
          border-bottom: 2px solid #e5e5e5;
          font-size: 14px;
          font-weight: 300;
          opacity: 0.55;
        }
        input[type="email"] {
          width: 100%;
          color: $black-color;
          padding: 12px 0px;
          margin: 8px 0;
          box-sizing: border-box;
          border: none;
          border-bottom: 2px solid #e5e5e5;
          font-size: 14px;
          font-weight: 300;
          @include respond-to("small") {
            width: 97%;
          }
        }
        input[type="email"]:focus {
          width: 100%;
          color: $black-color;
          padding: 12px 0px;
          margin: 8px 0;
          outline: none;
          border-bottom: 2px solid #e5e5e5;
          font-size: 14px;
          font-weight: 300;
          opacity: 0.8;
        }
        input[type="text"]:focus {
          width: 100%;
          color: $black-color;
          padding: 12px 0px;
          margin: 8px 0;
          outline: none;
          border-bottom: 2px solid #e5e5e5;
          font-size: 14px;
          font-weight: 300;
          opacity: 0.8;
        }

        .center-submit {
          text-align: center;
          input[type="submit"] {
            background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
            color: white;
            padding: 14px 30px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            margin: 10px 0;
            font-size: 20px;
            font-weight: 500;
            @include respond-to("small") {
              align-items: center;
              text-align: center;
              padding: 12px 40px;
              margin: 10% 30%;
            }
            @include respond-to("exsmall_320") {
              margin: 10% 25%;
            }
          }
        }

        label {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #222222;
          padding-bottom: 15px;
          display: inline-block;
          padding-top: 30px;
          @include respond-to("small") {
            padding-bottom: 0;
          }
        }
        textarea {
          width: 97%;
          color: $black-color;
          opacity: 0.55;
          box-sizing: border-box;
          border: none;
          border-bottom: 2px solid #e5e5e5;
          font-size: 14px;
          font-weight: 300;
          font-family: "Poppins";
          @include respond-to("small") {
            padding-top: 10px;
          }
        }
        textarea:focus {
          width: 97%;
          color: $black-color;
          opacity: 0.8;
          box-sizing: border-box;
          border: none;
          outline: none;
          border-bottom: 2px solid #e5e5e5;
          font-size: 14px;
          font-weight: 300;
          font-family: "Poppins";
          @include respond-to("small") {
            padding-top: 10px;
          }
        }
      }
    }
    @media screen and (min-width: 1600px) {
      // .form-layout{margin-top: -800px;}
    }
  }
}
@media screen and (min-width: 1600px) {
  .contact-page {
    padding-bottom: 0px;
  }
}

.error-message {
  color: red;
  font-size: 12px;
}
