// ===============================================
// == Home page expertise section styles =========
// ===============================================
.second-section {
  width: 100%;
  // margin-top: 2px;

  .expertise-description {
    padding-bottom: 40px;
    .para {
      font-size: 16px;
      text-align: center;
      color: $black-color;
      font-weight: 300;
      width: 55%;
      margin: 0 auto;
      @include respond-to("ex_large_1600") {
        // margin-bottom: 30px;
      }
      @include respond-to("medium_lg") {
        // margin-bottom: 50px;
      }

      @include respond-to("small") {
        width: 80%;
        // margin-bottom: 30px;
        font-size: 14px;
      }
    }
  }
  .expertise-section-wrapper {
    background-image: url("https://d3r4zj1odb19qx.cloudfront.net/assets/line2.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: contain;
    padding: 50px 0px;

    @include respond-to("ex_large") {
      background-size: contain;
      padding: 0px;
    }
    @include respond-to("small") {
      display: none;
    }
    .sector-expertise-card-wrapper-new {
      width: 75%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      // margin: -700px auto 0;
      justify-content: center;
      padding-bottom: 40px;
      margin: 150px auto 75px;
      @include respond-to("ex_large") {
        // margin: -532px auto 0;
      }
      @include respond-to("ex_large_1600") {
        // margin: -544px auto 0;
      }
      @include respond-to("ex_large_max") {
        // margin: -518px auto 0;
      }
      @include respond-to("large_1300") {
        // margin: -505px auto 0;
      }
      @include respond-to("large") {
        // margin: -340px auto 0;
      }
      @include respond-to("medium_lg") {
        // margin: -400px auto 0;
        width: 82%;
      }
      @include respond-to("medium") {
        // margin: -325px auto 0;
        padding-bottom: 0px;
        width: 94%;
      }
      @include respond-to("small") {
        display: none;
        width: 100%;
        // margin: 0px auto;
        padding-bottom: 0px;
      }
      .sector-expertise-card {
        position: relative;
        padding: 0px 2px;
        justify-content: center;
        width: 25%;
        align-items: center;
        margin: 0 auto 40px;
        background-color: $white-color;
        object-fit: contain;
        box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        z-index: 1;
        transition-duration: 0.3s;
        height: 245px;
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;

        &.active {
          margin-bottom: -46px;
        }
        &.down {
          margin-top: 0px;
          margin-bottom: 24px;
          box-shadow: none;
          background-color: #f3f4f4;
          opacity: 0.5;
        }
        @include respond-to("medium") {
          width: 27%;
          height: 200px;
        }
        @include respond-to("small") {
          margin: 5px;
          padding: 20px 8px 15px;
          width: 40%;
        }
        .sector-img-section {
          padding: 0px 15px 15px;
          @include respond-to("small") {
            padding: 0px;
          }
          .sector-img {
            object-fit: cover;
            object-position: top;
            &.inactive {
              filter: grayscale(1);
            }
            @include respond-to("medium") {
              width: 55%;
            }
            @include respond-to("small") {
              width: 45%;
            }
          }
          .sector-img2 {
            object-fit: cover;
            object-position: top;
            &.inactive {
              filter: grayscale(1);
            }
            @include respond-to("ex_large_1600") {
              margin-top: 12px;
            }
            @include respond-to("large_1300") {
              margin-top: 17px;
            }
            @include respond-to("medium") {
              width: 50%;
            }
            @include respond-to("small") {
              width: 43.5%;
            }
          }
        }

        .sector-details {
          .description {
            color: $black-color;
            font-size: 16px;
            font-weight: 500;
            padding: 0 20px 0px;

            @include respond-to("ex_large_max") {
              padding: 0 20px 0px;
            }

            @include respond-to("medium_lg") {
              padding: 0 20px;
              font-size: 12px;
            }
            @include respond-to("small") {
              padding: 5px;
              font-size: 9px;
            }
          }
          // .description2 {
          //   color: $black-color;
          //   font-size: 16px;
          //   font-weight: 500;
          //   padding: 0px 20px;
          //   @include respond-to("ex_large_1600") {
          //     padding: 10px 20px;
          //   }

          //   @include respond-to("ex_large_max") {
          //     padding: 0 20px 0px;
          //   }
          //   @include respond-to("large_1300") {
          //     padding: 6px 20px;
          //   }

          //   @include respond-to("medium_lg") {
          //     padding: 0 20px;
          //     font-size: 12px;
          //   }
          //   @include respond-to("small") {
          //     padding: 5px;
          //     font-size: 9px;
          //   }
          // }
        }
        &:nth-child(1) {
        }
        &:nth-child(2) {
          margin: -304px 0 40px;
          &.down {
            margin-top: 0px;
            box-shadow: none;
            background-color: #f3f4f4;
            margin-bottom: 0px;
            opacity: 0.5;
          }
          &.active {
            margin-bottom: -46px;
            margin-top: 0px;
          }
        }
        &:nth-child(3) {
        }
        &:nth-child(10) {
          &.active {
            margin-bottom: 0;
            margin-top: -60px;
          }
          &.down {
            margin-top: 22px;
            margin-bottom: 0px;
            box-shadow: none;
            background-color: #f3f4f4;
            opacity: 0.5;
          }
        }
        &:nth-child(11) {
          margin: -304px 0px 40px;
          &.down {
            margin-top: 32px;
            margin-bottom: 0px;
            box-shadow: none;
            background-color: #f3f4f4;
            opacity: 0.5;
          }
          &.active {
            margin-bottom: 0px;
            margin-top: -46px;
          }
        }
        &:nth-child(12) {
          &.active {
            margin-bottom: 0;
            margin-top: -60px;
          }
          &.down {
            margin-top: 22px;
            margin-bottom: 0px;
            box-shadow: none;
            background-color: #f3f4f4;
            opacity: 0.5;
          }
        }
        &:nth-child(14) {
          margin: -154px 0 40px;
          &.down {
            margin-top: 0px;
            box-shadow: none;
            background-color: #f3f4f4;
            margin-bottom: 0px;
            opacity: 0.5;
          }
          &.active {
            margin: -20px auto 40px;
          }
        }
      }

      .sector-card-detail {
        display: none;

        &.active {
          position: relative;
          display: block;
          background-color: $white-color;
          width: 100%;
          padding: 40px 20px 40px 20px;
          object-fit: contain;
          box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
          border-radius: 8px;
          transition-duration: 0.3s;
          @include transitinTime;
          @include respond-to("small") {
            display: none;
          }
        }

        ul {
          width: 80%;
          margin: 0 auto;
        }
        h4 {
          margin: 0px auto;
          color: #3d3d3d;
          font-size: 16px;
          font-weight: 600;
          padding: 0px 12px 34px;
          text-align: center;
        }
        .image-section {
          display: flex;
          flex-flow: wrap;
          flex-grow: 6;
          justify-content: center;
          .card-container {
            width: 15%;
            object-fit: contain;
            border-radius: 16px;
            background-color: #fff;
            padding: 12px 0;
            justify-content: center;
            margin: 0 auto;
            display: flex;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
// ====================================================
// == Home page expertise section-mobile styles =======
// ====================================================
.sector-expertise-card-wrapper-mob {
  width: 69%;

  flex-flow: row wrap;
  align-items: center;
  margin: -530px auto 0;
  justify-content: center;
  padding-bottom: 40px;
  display: none;
  @include respond-to("ex_large") {
    margin: -480px auto 0;
  }
  @include respond-to("ex_large_1600") {
    margin: -420px auto 0;
  }
  @include respond-to("ex_large_max") {
    margin: -300px auto 0;
  }
  @include respond-to("large") {
    margin: -340px auto 0;
  }
  @include respond-to("medium") {
    margin: -200px auto 0;
    padding-bottom: 0px;
    width: 83%;
  }
  @include respond-to("small") {
    display: flex;
    width: 100%;
    margin: 0px auto;
    padding-bottom: 30px;
  }

  .sector-expertise-card-mob {
    padding: 15.6px 2px;
    justify-content: center;
    width: 28%;
    align-items: center;
    margin: 0 auto 40px;
    background-color: $white-color;
    object-fit: contain;
    box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    transition-duration: 0.3s;
    min-height: 103.75px;

    &.active {
      margin-bottom: -46px;
    }
    &.down {
      margin-top: 0px;
      margin-bottom: 0px;
      box-shadow: none;
      background-color: #f3f4f4;
      opacity: 0.5;
    }
    @include respond-to("small") {
      margin: 5px;
      padding: 20px 8px;
      width: 40%;
    }
    @include respond-to("exsmall_320") {
      margin: 5px;
      padding: 20px 8px;
      width: 40%;
      min-height: 97.75px;
    }
    .sector-img-section {
      padding: 15px;
      @include respond-to("small") {
        padding: 0px;
      }
      .sector-img {
        object-fit: cover;
        object-position: top;
        &.inactive {
          filter: grayscale(1);
        }
        @include respond-to("medium") {
          width: 55%;
        }
        @include respond-to("small") {
          width: 45%;
        }
      }
      .sector-img4 {
        object-fit: cover;
        object-position: top;
        &.inactive {
          filter: grayscale(1);
        }
        @include respond-to("medium") {
          width: 55%;
        }
        @include respond-to("small") {
          width: 45%;
        }
        @include respond-to("exsmall_320") {
          width: 45%;
        }
      }
      .sector-img2 {
        object-fit: cover;
        object-position: top;
        &.inactive {
          filter: grayscale(1);
        }
        @include respond-to("medium") {
          width: 50%;
        }
        @include respond-to("small") {
          width: 45%;
        }
      }
      .sector-img3 {
        object-fit: cover;
        object-position: top;
        &.inactive {
          filter: grayscale(1);
        }
        @include respond-to("medium") {
          width: 50%;
        }
        @include respond-to("small") {
          width: 45%;
        }
        // @include respond-to("exsmall_320") {
        //   width: 46.5%;
        // }
      }
    }

    .sector-details {
      .description {
        color: $black-color;
        font-size: 18px;
        font-weight: 500;
        padding: 0 40px 20px;
        @include respond-to("ex_large_max") {
          padding: 0 20px 20px;
        }
        @include respond-to("medium_lg") {
          padding: 0 20px;
          font-size: 12px;
        }
        @include respond-to("small") {
          padding: 5px;
          font-size: 9px;
        }
        @include respond-to("exsmall_320") {
          padding: 0px;
          font-size: 9px;
        }
      }

      @media screen and (min-width: 1600px) {
        .description {
          padding: 0 75px 20px;
        }
      }
    }
    &:nth-child(13) {
      &.active {
        margin-bottom: -20px;
      }
    }
  }
  .sector-card-detail {
    display: none;
    &.active {
      display: block;
      background-color: $white-color;
      width: 100%;
      padding: 10px;
      object-fit: contain;
      box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
      border-radius: 8px;
      transition-duration: 0.3s;
      @include transitinTime;
      // height: 55vh;
      @include respond-to("exsmall_574") {
        // height: 52vh;
      }
      @include respond-to("exsmall") {
        // height: 55vh;
      }
      @include respond-to("exsmall_320") {
        // height: 60vh;
      }
    }

    ul {
      width: 80%;
      margin: 0 auto;
    }
    h4 {
      margin: 12px auto;
      color: #3d3d3d;
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
      text-align: center;
    }
    .image-section {
      display: flex;
      flex-wrap: wrap;
      padding: 12px 0px;
      .card-container {
        width: 50%;
        // height: 100px;
        object-fit: contain;
        border-radius: 16px;
        background-color: #fff;
        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        // border-radius: 8px;
        margin: 10px auto;
        img {
          width: 100%;
          // margin: 10px;
        }
      }
    }
  }
}
