// =========================================
// ========== Home page styles =============
// =========================================

//-------------------Home banner section styles-------------------

.home-content {
  background-image: linear-gradient(360deg, #ebecec, #f6f7f7);
  display: flex;
  width: 100%;
  // top: 14%;
  text-align: center;
  margin: auto 0px;
  padding: 100px 0px 0px;
  @include respond-to("medium_lg") {
    display: block;
    padding: 40px 0px 20px;
  }
  .heading {
    width: 50%;
    position: relative;
    @include respond-to("medium_lg") {
      width: 100%;
    }
    img {
      width: 70%;
      min-height: 521px;
      @include respond-to("large") {
        min-height: 446px;
      }
      @include respond-to("medium_lg") {
        min-height: 306px;
        // width: 100%;
      }
      @include respond-to("small") {
        width: 100%;
        min-height: 430px;
      }
    }

    .light2 {
      position: absolute;
      top: 21.3%;
      left: 35.4%;
      width: 5px;
      height: 5px;
      @include respond-to("ex_large") {
        top: 21%;
      }
      @include respond-to("ex_large_max") {
        top: 21%;
        left: 35.5%;
      }
      @include respond-to("medium_lg") {
        top: 20%;
        left: 35.4%;
      }
      @include respond-to("medium") {
        top: 20%;
        left: 35.2%;
      }
      @include respond-to("small") {
        top: 22.5%;
        left: 29.2%;
      }
      background-image: radial-gradient(#fbfbfb 23%, #ffa47a 64%);
      box-shadow: 0 0 8px 6px #ff7d6f, 0 0 14px 9px #ea9867,
        0 0 30px 15px #f9c3a2;
      border-radius: 50%;
      @include light-blinking;
    }
    .light-right2 {
      position: absolute;
      top: 21.3%;
      left: 60.4%;
      width: 5px;
      height: 5px;
      @include respond-to("ex_large") {
        top: 21%;
      }
      @include respond-to("ex_large_max") {
        top: 21%;
        left: 60.4%;
      }
      @include respond-to("medium_lg") {
        top: 20%;
        left: 60.4%;
      }
      @include respond-to("medium") {
        top: 20%;
        left: 60.4%;
      }
      @include respond-to("small") {
        top: 22.5%;
        left: 64.4%;
      }
      background-image: radial-gradient(#fbfbfb 23%, #ffa47a 64%);
      box-shadow: 0 0 8px 6px #ff7d6f, 0 0 14px 9px #ea9867,
        0 0 30px 15px #f9c3a2;
      border-radius: 50%;
      @include light-blinking;
    }
    .title-container {
      margin-top: -99px;
      // @include respond-to("ex_large") {
      //   margin-top: -100px;
      // }
      // @include respond-to("ex_large_max") {
      //   margin-top: -100px;
      // }
      @include respond-to("medium_lg") {
        margin-top: -130px;
      }
      @include respond-to("medium") {
        margin-top: -120px;
      }
      @include respond-to("small") {
        margin-top: -100px;
      }
      // @include respond-to("exsmall_574") {
      //   margin-top: -77px;
      // }
      .title {
        line-height: 1;
        font-size: 56px;
        font-weight: 600;
        letter-spacing: 0.51px;
        color: #3d3d3d;

        @include respond-to("ex_large") {
          font-size: 56px;
        }
        @include respond-to("medium_lg") {
          font-size: 100px;
        }
        @include respond-to("medium") {
          font-size: 90px;
        }

        @include respond-to("small") {
          font-size: 56px;
        }
        @include respond-to("exsmall_574") {
          font-size: 36px;
        }
      }
      .sub-title {
        font-size: 20px;
        font-weight: normal;
        color: #3d3d3d;
        @include respond-to("medium_lg") {
          font-size: 36px;
        }
        @include respond-to("medium") {
          font-size: 30px;
        }
        @include respond-to("small") {
          font-size: 16px;
        }
      }
      a {
        button {
          background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
          border: none;
          display: inline-block;
          text-align: center;
          border-radius: 4px;
          padding: 12px 20px;
          //width: 165px;
          font-size: 16px;
          font-weight: 300;
          outline: none;
          cursor: pointer;
          color: #ffffff;
          margin: 14px auto;
        }
      }
    }
  }
}
