// ======================================
// =============== Mixins ===============
// ======================================

//----------------------------------Mobile responsive settings code snippet----------------------------------
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

//----------------------------------Mobile responsive © Sinhalion created By Sachith 05-18-2021----------------------------------
@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpointsmq) {
  $mq-media-query: "screen and";

  @if $mq-breakpoint == landscape or $mq-breakpoint == portrait {
    $mq-media-query: "#{$mq-media-query} (orientation: #{$mq-breakpoint})";
  } @else if $mq-breakpoint == retina {
    $mq-media-query: "#{$mq-media-query} (-webkit-min-device-pixel-ratio: 2),"+"#{$mq-media-query} (min-resolution: 2dppx)";
  } @else {
    // If $mq-breakpoint is a key that exists in
    // $mq-breakpoints, get and use the value
    @if map-has-key($mq-breakpoints, $mq-breakpoint) {
      $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
    }
    $mq-media-query: "#{$mq-media-query} (min-width: #{$mq-breakpoint})";
  }

  @media #{$mq-media-query} {
    @content;
  }
}

$breakpointsmq: (
  exsmall: 375px,
  small: 767px,
  medium: 991px,
  medium_lg: 1024px,
  ex_large_max: 1440px,
);

//--------profile Card transition time--------------
@mixin transitinTime {
  transition-duration: 0.8s;
  transition-delay: 0.15s;
  @include respond-to("small") {
    transition-duration: 0.5s;
    transition-delay: 0s;
  }
}

//--------- member card text faid in --------------
@mixin cad_text_faid {
  -webkit-animation: fade-in 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin card_desc_fade {
  -webkit-animation: fade-in 1s ease-in;
  animation: fade-in 1s ease-in;

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin inner_card_fade {
  -webkit-animation: fade-in 0.4s ease-in;
  animation: fade-in 0.4s ease-in;

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

//------ bridge light blinking -------
@mixin light-blinking {
  -webkit-animation: blink-1 8s ease-in infinite both;
  animation: blink-1 8s ease-in infinite both;

  @-webkit-keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0.05;
    }
  }
  @keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0.05;
    }
  }
}

@mixin line-shadow {
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
  z-index: -3;
  @-webkit-keyframes glowing {
    0% {
      opacity: 1;
      -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 52, 1, 0.8));
    }
    50% {
      opacity: 0;
      -webkit-filter: drop-shadow(0px 0px 8px rgba(223, 31, 31, 0.8));
    }
    100% {
      opacity: 1;
      -webkit-filter: drop-shadow(0px 0px 8px rgba(116, 7, 7, 0.8));
    }
  }
  @keyframes glowing {
    0% {
      opacity: 1;
      -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 51, 0, 0.8));
    }
    50% {
      opacity: 0;
      -webkit-filter: drop-shadow(0px 0px 8px rgba(228, 56, 56, 0.8));
    }
    100% {
      opacity: 1;
      -webkit-filter: drop-shadow(0px 0px 8px rgba(119, 2, 2, 0.8));
    }
  }
}
@mixin polygonShadow {
  filter: drop-shadow(0 15px 15px rgba(151, 151, 151, 0.5));
}

@mixin scrollBar {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    margin: 0px 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d9d9d9;
    border-radius: 40px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4;
  }
}
