// =================================================
// ========== common elements styles ===============
// =================================================

// .card-center {
//   margin-top: -75px;
//   margin-right: 20px;
//   margin-left: 20px;
//   border-radius: 8px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-evenly;
//   padding: 24px 24px;
//   width: 40%;
//   z-index: 3;
//   // transform: scale(1);
//   transition: 0.2s ease-in-out;
//   box-shadow: 0 10px 30px 0 rgba(153, 153, 153, 0.2);
//   background-color: $white-color;
//   min-height: 220px;
//   &:hover {
//     transform: scale(1.05);
//   }
//   @include respond-to("medium") {
//     width: 80%;
//     min-height: 130px;
//     padding: 40px 20px 20px 20px;
//   }
//   @include respond-to("small") {
//     padding: 40px 20px 20px 20px;
//     width: 50%;
//     min-height: 100px;
//     margin: 20px 8px -346px;
//   }
//   @include respond-to("exsmall_320") {
//     margin: 40px 8px -316px;
//   }
//   .card-main {
//     span {
//       color: #f3722191;
//     }
//     .percentage {
//       display: flex;
//       font-size: 48px;
//       font-weight: bold;
//       text-align: center;
//       color: #f37021;
//       line-height: 0.69;
//       @include respond-to("medium") {
//         font-size: 40px;
//       }
//       @include respond-to("small") {
//         font-size: 22px;
//       }
//     }
//   }
//   .card-title {
//     .center-title {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       @include respond-to("medium") {
//         margin: 18px 0px;
//       }
//       h3 {
//         font-size: 22px;
//         font-weight: 500;
//         text-align: center;
//         color: $black-color;
//         line-height: 1.38;
//         @include respond-to("medium") {
//         }
//         @include respond-to("small") {
//           font-size: 13px;
//         }
//         @include respond-to("exsmall_574") {
//         }
//       }
//       span {
//         font-size: 36px;
//         @include respond-to("small") {
//           font-size: 24px;
//         }
//       }
//       h4 {
//         width: 50%;
//         width: 50%;
//         font-size: 18px;
//         font-weight: 500;
//         color: $black-color;
//         line-height: 1.38;
//         padding: 0px 6px;
//         @include respond-to("small") {
//           font-size: 13px;
//         }
//       }
//     }
//     .title-h3 {
//     }
//   }
//   .card-details {
//     p {
//       padding: 0 25px;
//       //margin: 0 auto;
//       font-size: 14px;
//       font-weight: 300;
//       text-align: center;
//       color: $black-color;
//       line-height: 1.44;
//     }
//     @include respond-to("medium") {
//       font-size: 14px;
//       font-weight: 300;
//       padding: 0 2px;
//     }
//     @include respond-to("small") {
//       font-size: 14px;
//       font-weight: 300;
//       padding: 0 15px;
//     }
//     @include respond-to("exsmall_320") {
//       font-size: 12px;
//     }
//   }
// }

.sector-expertise {
  overflow: hidden;
  .sector-expertise-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .sector-expertise-title-h2 {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      color: #222222;
      margin-bottom: 20px;
      @include respond-to("medium") {
        font-size: 32px;
        margin-bottom: 8px;
        padding: 0px 12px;
      }
      @include respond-to("small") {
        padding: 0px 12px;
        font-size: 24px;
        margin-bottom: 3px;
      }
    }
    .horizontal-line {
      width: 70px;
      height: 8px;
      margin-bottom: 40px;
      border-radius: 4px;
      background-image: linear-gradient(to bottom, #f37021, #fddb31);
      @include respond-to("medium") {
        width: 65px;
        height: 6px;
        margin-bottom: 40px;
      }
      @include respond-to("small") {
        width: 36px;
        height: 4px;
        margin-bottom: 30px;
      }
    }
    .inner-horizontal-line {
      width: 70px;
      height: 8px;
      margin-bottom: 60px;
      border-radius: 4px;
      background-image: linear-gradient(to bottom, #f37021, #fddb31);
      @include respond-to("medium") {
        width: 65px;
        height: 6px;
        margin-bottom: 70px;
      }
      @include respond-to("small") {
        width: 36px;
        height: 4px;
        margin-bottom: 40px;
      }
    }
  }
}
//----------------inner pages main style-------------------//
.inner-page-wrapper {
  padding-top: 100px;
  // padding-bottom: 0px;
  // background-image: linear-gradient(369deg, #ebecec, #ececec);
  background-image: linear-gradient(360deg, #ebecec, #f6f7f7);

  @include respond-to("medium") {
    // padding-bottom: 50px;
    padding-top: 50px;
  }

  @include respond-to("small") {
    padding-top: 90px;
    // padding-bottom: 60px;
  }
}
//-------------inner page hero section ---------------------//
.hero-section {
  display: flex;
  height: 70vh;
  @include respond-to("ex_large") {
    height: 70vh;
  }
  @include respond-to("ex_large_max") {
    height: 70vh;
  }
  @include respond-to("ex_large_1600") {
    height: 74vh;
  }
  @include respond-to("large") {
  }
  @include respond-to("medium_lg") {
    height: 40vh;
  }
  @include respond-to("small") {
    display: block;
    height: 78vh;
  }
  @include respond-to("exsmall_320") {
    margin-top: -20px;
    height: 95vh;
  }
  .left-side {
    width: 50%;
    // margin: auto 0px;
    @include respond-to("small") {
      width: 100%;
    }
    .left-content-wrapper {
      .title-menu {
        display: flex;
        padding-top: 80px;
        @include respond-to("ex_large") {
          padding-top: 150px;
        }
        @include respond-to("ex_large_1600") {
          padding-top: 100px;
        }
        @include respond-to("ex_large_max") {
          padding-top: 80px;
        }
        @include respond-to("large") {
        }
        @include respond-to("medium_lg") {
        }
        @include respond-to("small") {
          padding-top: 0px;
        }
        .line {
          height: 3px;
          width: 23%;
          background-color: $primary-color;
          margin: auto 0px;
          @include respond-to("small") {
            width: 20%;
          }
        }
        .dot {
          height: 15px;
          width: 15px;
          background-color: $primary-color;
          border-radius: 50%;
          display: inline-block;
          margin: auto 0px;
          @include respond-to("small") {
            width: 10px;
            height: 10px;
          }
        }
        h4 {
          font-size: 18px;
          font-weight: 600px;
          color: $text-color;
          padding: 0px 12px;
          @include respond-to("small") {
            font-size: 16px;
            font-weight: 300px;
          }
        }
      }
      .content-wrapper {
        margin-left: 25%;
        color: $text-color;
        padding: 0px 12px;
        h1 {
          font-size: 56px;
          @include respond-to("medium_lg") {
            font-size: 42px;
          }
          @include respond-to("small") {
            font-size: 24px;
            margin-left: -3%;
          }
        }
        p {
          font-size: 24px;
          font-weight: normal;
          @include respond-to("medium") {
            font-size: 20px;
          }
          @include respond-to("small") {
            font-size: 18px;
            margin-left: -2%;
          }
        }
        .hero-description {
          padding-top: 100px;
          padding-bottom: 60px;
          @include respond-to("large_1300") {
            padding-top: 48px;
          }
          @include respond-to("medium") {
            padding-top: 28px;
          }
          @include respond-to("small") {
            margin-left: -75px;
          }
          p {
            // width: 80%;
            font-size: 16px;
            border-left: 2px solid $primary-color;
            padding: 0px 12px;
            @include respond-to("medium_lg") {
              font-size: 12px;
            }
          }
        }
        // .card-container{
        //   width: 77%;
        //   margin: 0 auto;
        //   padding: 60px 40px;
        //   @include respond-to("exsmall_320") {
        //     padding: 60px 35px;
        //   }

        // }
      }
    }
  }
  .right-side {
    width: 50%;
    margin: 0px;

    @include respond-to("ex_large") {
      margin: auto 0px;
    }
    @include respond-to("ex_large_max") {
      margin: 0px;
    }
    @include respond-to("large") {
    }
    @include respond-to("medium_lg") {
      height: 40vh;
    }
    @include respond-to("small") {
      width: 100%;
    }
    .image-wrapper {
      img {
        width: 85%;
        padding-top: 100px;

        @include respond-to("ex_large") {
          padding-top: 0px;
        }
        @include respond-to("ex_large_max") {
          padding-top: 100px;
        }
        @include respond-to("large") {
        }
        @include respond-to("medium_lg") {
          height: 25vh;
        }
        @include respond-to("small") {
          padding-top: 50px;
          width: 100%;
          height: 40vh;
          padding-bottom: 50px;
        }
      }
    }
  }
}
//--------------hero section team card -------------------//
.hero-section-team {
  display: flex;
  height: 100vh;
  @include respond-to("ex_large") {
    height: 100vh;
  }
  @include respond-to("ex_large_max") {
    height: 100vh;
  }
  @include respond-to("large") {
  }
  @include respond-to("medium_lg") {
    height: 40vh;
  }
  @include respond-to("medium") {
    height: 50vh;
  }
  @include respond-to("small") {
    display: block;
    height: 78vh;
    //margin-top: -23px;
  }
  @include respond-to("exsmall_320") {
    margin-top: -23px;
    height: 90vh;
  }

  .left-side {
    width: 50%;
    // margin: auto 0px;
    @include respond-to("small") {
      width: 100%;
    }
    .left-content-wrapper {
      .title-menu {
        display: flex;
        padding-top: 80px;
        @include respond-to("ex_large") {
          padding-top: 150px;
        }
        @include respond-to("ex_large_1600") {
          padding-top: 100px;
        }
        @include respond-to("ex_large_max") {
          padding-top: 80px;
        }
        @include respond-to("large") {
        }
        @include respond-to("medium_lg") {
        }
        @include respond-to("small") {
          padding-top: 0;
        }

        .line {
          height: 3px;
          width: 23%;
          background-color: $primary-color;
          margin: auto 0px;
          @include respond-to("small") {
            width: 20%;
          }
        }
        .dot {
          height: 15px;
          width: 15px;
          background-color: $primary-color;
          border-radius: 50%;
          display: inline-block;
          margin: auto 0px;
          @include respond-to("small") {
            width: 10px;
            height: 10px;
          }
        }
        h4 {
          font-size: 18px;
          font-weight: 600px;
          color: $text-color;
          padding: 0px 12px;
          @include respond-to("small") {
            font-size: 16px;
            font-weight: 300px;
          }
        }
      }
      .content-wrapper {
        margin-left: 25%;
        color: $text-color;
        padding: 0px 12px;
        h1 {
          font-size: 56px;
          @include respond-to("medium_lg") {
            font-size: 42px;
          }
          @include respond-to("small") {
            font-size: 24px;
            margin-left: -4%;
          }
        }
        p {
          font-size: 24px;
          font-weight: normal;
          @include respond-to("medium") {
            font-size: 20px;
          }
        }
        .hero-description {
          padding-top: 100px;
          @include respond-to("large_1300") {
            padding-top: 48px;
          }
          @include respond-to("medium") {
            padding-top: 28px;
          }
          @include respond-to("small") {
            margin-left: -75px;
          }
          p {
            // width: 80%;
            font-size: 16px;
            border-left: 2px solid $primary-color;
            padding: 0px 12px;
            @include respond-to("medium_lg") {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .right-side {
    width: 50%;
    margin: 0px;

    @include respond-to("ex_large") {
      margin: auto 0px;
    }
    @include respond-to("ex_large_max") {
      margin: 0px;
    }
    @include respond-to("large") {
    }
    @include respond-to("medium_lg") {
      height: 40vh;
    }
    @include respond-to("small") {
      width: 100%;
    }
    .image-wrapper {
      img {
        width: 85%;
        padding-top: 100px;

        @include respond-to("ex_large") {
          padding-top: 0px;
        }
        @include respond-to("ex_large_max") {
          padding-top: 100px;
        }
        @include respond-to("large") {
        }
        @include respond-to("medium_lg") {
          height: 30vh;
        }
        @include respond-to("small") {
          padding-top: 50px;
          width: 100%;
          height: 40vh;
          padding-bottom: 50px;
        }
        @include respond-to("exsmall_320") {
          padding-top: 20px;
        }
      }
    }
  }
}
//------------hero section leadership ---------------------//

.hero-section-leadership {
  display: flex;
  height: 39vh;
  min-height: 420px;
  @include respond-to("ex_large") {
    height: 39vh;
    min-height: 420px;
  }
  @include respond-to("ex_large_1600") {
    height: 34vh;
    min-height: 380px;
  }
  @include respond-to("ex_large_max") {
    height: 50vh;
    min-height: 450px;
  }
  @include respond-to("large_1300") {
    height: 52vh;
    min-height: 400px;
  }
  @include respond-to("large") {
  }
  @include respond-to("medium_lg") {
    height: 100%;
    min-height: 429px;
  }
  @include respond-to("medium") {
    height: 100%;
    display: block;
  }

  @include respond-to("small") {
    height: 80%;
    display: block;
  }
  @include respond-to("exsmall_574") {
    height: 80%;
    display: block;
  }
  @include respond-to("exsmall") {
    height: 100%;
    display: block;
    min-height: 624px;
  }
  // @include respond-to("exsmall_320") {
  //   height: 100%;
  //   display: block;
  //   min-height: 624px;
  // }
  .left-side {
    width: 50%;
    // margin: auto 0px;
    @include respond-to("ex_large_max") {
      width: 60%;
    }
    @include respond-to("large_1300") {
      width: 70%;
    }
    @include respond-to("medium_lg") {
      width: 50%;
    }
    @include respond-to("medium") {
      width: 100%;
    }
    @include respond-to("small") {
      width: 100%;
    }
    .left-content-wrapper {
      .title-menu {
        display: flex;
        padding-top: 50px;
        @include respond-to("ex_large") {
          padding-top: 50px;
        }
        @include respond-to("ex_large_1600") {
          padding-top: 50px;
        }
        @include respond-to("ex_large_max") {
          padding-top: 50px;
        }
        @include respond-to("large") {
        }
        @include respond-to("medium_lg") {
        }

        @include respond-to("medium") {
          width: 100%;
          padding-top: 32px;
        }
        @include respond-to("small") {
          width: 100%;
          padding-top: 12px;
        }
        .line {
          height: 3px;
          width: 16%;
          background-color: $primary-color;
          margin: auto 0px;
          // @include respond-to("medium") {
          //   padding: 0px;
          //   // width: 20%;
          // }
          @include respond-to("small") {
            width: 14%;
          }
        }
        .dot {
          height: 15px;
          width: 15px;
          background-color: $primary-color;
          border-radius: 50%;
          display: inline-block;
          margin: auto 0px;
          @include respond-to("small") {
            height: 10px;
            width: 10px;
          }
        }
        h4 {
          font-size: 56px;
          font-weight: 700;
          color: $text-color;
          padding: 0px 12px;
          width: 77%;
          @include respond-to("large") {
            font-size: 48px;
          }
          @include respond-to("medium_lg") {
            font-size: 36px;
          }
          @include respond-to("medium") {
            font-size: 32px;
          }
          @include respond-to("small") {
            font-size: 24px;
            width: 75%;
            // margin-left: -3%;
          }
          @include respond-to("exsmall_320") {
            font-size: 22px;
            // margin-left: -3%;
          }
        }
      }
      .content-wrapper {
        margin-left: 20%;
        color: $text-color;
        padding: 0px 12px;
        @include respond-to("medium") {
          padding: 0px 32px;
        }
        @include respond-to("small") {
          margin-left: 25%;
          padding: 0px 12px;
        }
        h1 {
          font-size: 56px;
          @include respond-to("medium_lg") {
            font-size: 42px;
          }
          @include respond-to("medium") {
            font-size: 24px;
            margin-left: -3%;
          }
        }
        p {
          font-size: 24px;
          font-weight: normal;
          text-align: justify;

          @include respond-to("medium") {
            font-size: 18px;
          }
        }
        .hero-description {
          // padding-top: 100px;
          margin: 57px 0px;
          @include respond-to("large_1300") {
            // padding-top: 48px;
          }
          @include respond-to("medium") {
            margin-left: -75px;
          }
          p {
            // width: 80%;
            font-size: 16px;
            border-left: 2px solid $primary-color;
            padding: 0px 12px;
            @include respond-to("medium_lg") {
              font-size: 16px;
            }
            @include respond-to("exsmall_320") {
              font-size: 14px;
            }
          }
        }
        .sub-description2 {
          // margin: 60px 0px 24px;
          align-items: center;
          width: 94%;
          margin: 0px auto;
          @include respond-to("ex_large_1600") {
            // margin: 40px 0px 0px;
          }
          // @include respond-to("medium") {
          //   margin-top: 30px;
          // }
          @include respond-to("medium") {
            margin-top: 30px;
            margin-left: -66px;
          }
          .sub-despara {
            // text-align: left;
            width: 94%;
            margin: 0px auto;
            @include respond-to("ex_large_1600") {
              font-size: 19px;
            }
            @include respond-to("large_1300") {
              font-size: 18px;
            }
            @include respond-to("medium") {
              font-size: 12px;
            }
            @include respond-to("small") {
              font-size: 18px;
            }
          }
        }
        .btn-wrapper {
          text-align: left;
          margin: 20px 0px;
          @include respond-to("ex_large_1600") {
            //margin: 5px 0;
          }
          a {
            button {
              background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
              border: none;
              display: inline-block;
              text-align: center;
              border-radius: 4px;
              padding: 12px 20px;
              //width: 165px;
              font-size: 16px;
              font-weight: 300;
              outline: none;
              cursor: pointer;
              color: #ffffff;
              margin: 14px auto;
              @include respond-to("ex_large_1600") {
                margin-top: 5px;
                font-size: 15px;
              }
              @include respond-to("medium") {
                font-size: 12px;
              }
              @include respond-to("small") {
                //margin-top: 30px;
                margin-left: -66px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .right-side {
    width: 50%;
    margin: 0px;

    @include respond-to("ex_large") {
      margin: 0px;
    }
    @include respond-to("ex_large_max") {
      margin: 0px;
    }
    @include respond-to("large") {
    }
    @include respond-to("medium_lg") {
      width: 50%;
    }
    @include respond-to("medium") {
      width: 100%;
    }
    @include respond-to("small") {
      width: 100%;
    }
    .image-wrapper {
      img {
        width: 55%;
        margin: 0 auto;
        justify-content: center;
        display: flex;

        @include respond-to("ex_large") {
          width: 55%;
          margin: 0 auto;
          justify-content: center;
          display: flex;
        }
        @include respond-to("ex_large_1600") {
          width: 60%;
        }
        @include respond-to("ex_large_max") {
          width: 78%;
          margin: 0;
          justify-content: center;
          display: flex;
          padding-top: 50px;
        }
        @include respond-to("large_1300") {
          width: 74%;
          margin: auto;
          justify-content: center;
          display: flex;
          padding-top: 60px;
        }
        @include respond-to("large") {
          padding-top: 50px;
        }
        @include respond-to("medium_lg") {
          width: 80%;
          margin: 0;
          padding-top: 80px;
        }
        @include respond-to("medium") {
          width: 75%;
          padding-top: 0px;
          margin: 0px auto;
        }
        @include respond-to("small") {
          padding-top: 30px;
          width: 100%;
          padding-bottom: 50px;
        }
        @include respond-to("exsmall") {
          padding-top: 5px;
          width: 100%;
          // height: 40vh;
          padding-bottom: 50px;
        }
      }
    }
  }
}

.values-title {
  display: flex;
  align-items: center;
  margin-left: 13%;
  padding-top: 5%;
  @include respond-to("small") {
    margin-left: 8%;
  }
  span {
    width: 5px;
    height: 40px;
    border-radius: 50px;
    background-image: linear-gradient(to bottom, #f37021, #fddb31);
    @include respond-to("small") {
      height: 30px;
    }
  }
  h2 {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #000000;
    opacity: 0.9;
    margin-left: 16px;
    @include respond-to("small") {
      font-size: 24px;
      width: 81%;
    }
    @include respond-to("exsmall_320") {
      font-size: 22px;
      width: 88%;
    }
  }
}
.new-modal {
  z-index: 1040;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  width: 50%;
  // position: absolute;
  top: 50%;
  left: 50%;
  /* margin-right: -50%; */
  transform: translate(-50%, -50%);
  margin: 0px auto;
}

//------------This code used for the portfolio company card pop up-------------------
.popup-page {
  .App-header {
    opacity: 0.1;
  }
  footer {
    display: none;
  }
  .footer_mobile {
    display: none;
  }
}

//================About  sub titles===============

.sector-expertise-sub-title {
  display: flex;
  // flex-direction: column;
  align-items: left;
  padding: 0px 24px;
  @include respond-to("small") {
    padding: 0px 15px;
  }
  span {
    width: 5px;
    height: 40px;
    border-radius: 50px;
    margin-left: 7%;
    background-image: linear-gradient(to bottom, #f37021, #fddb31);
    @include respond-to("medium_lg") {
      margin-left: 6%;
    }
    @include respond-to("medium") {
      margin-left: 4%;
    }
    @include respond-to("small") {
      width: 3px;
      height: 30px;
      //margin-left: -2%;
    }
    @include respond-to("exsmall_574") {
      margin-left: 5%;
    }
    @include respond-to("exsmall") {
      width: 3px;
      height: 30px;
      //margin-left: -2%;
    }
    @include respond-to("exsmall_320") {
      margin-left: 7%;
    }
  }
  .sector-expertise-title-h3 {
    font-size: 30px;
    font-weight: 200;
    text-align: left;
    color: #222222;
    margin-bottom: 20px;
    margin-left: 12px;
    // @include respond-to("medium") {
    //   font-size: 32px;
    //   margin-bottom: 8px;
    //   padding: 0px 12px;
    // }
    @include respond-to("small") {
      // padding: 0px 12px;
      font-size: 18px;
      margin-bottom: 20px;
      margin-left: 9px;
    }
  }
}

.sector-expertise-sub-title2 {
  display: flex;
  // flex-direction: column;
  align-items: left;
  padding: 0px 24px;
  @include respond-to("small") {
    padding: 0px 15px;
  }
  span {
    width: 5px;
    height: 40px;
    border-radius: 50px;
    margin-left: 7%;
    background-image: linear-gradient(to bottom, #f37021, #fddb31);
    @include respond-to("medium_lg") {
      margin-left: 6%;
    }
    @include respond-to("medium") {
      height: 45px;
      margin-left: 4%;
    }
    @include respond-to("small") {
      width: 4px;
      height: 53px;
      //margin-left: -2%;
    }
    @include respond-to("exsmall_574") {
      margin-left: 5%;
    }
    @include respond-to("exsmall") {
      width: 4px;
      height: 58px;
      //margin-left: -2%;
    }
    @include respond-to("exsmall_320") {
      margin-left: 7%;
    }
  }
  .sector-expertise-title-h4 {
    font-size: 30px;
    font-weight: 200;
    text-align: left;
    color: #222222;
    margin-bottom: 20px;
    margin-left: 12px;
    // @include respond-to("medium") {
    //   font-size: 32px;
    //   margin-bottom: 8px;
    //   padding: 0px 12px;
    // }
    @include respond-to("small") {
      // padding: 0px 12px;
      font-size: 18px;
      margin-bottom: 20px;
      margin-left: 9px;
    }
  }
}

//====================description styles============

.transaction-description {
  .para {
    font-size: 16px;
    text-align: center;
    color: $black-color;
    font-weight: 300;
    width: 55%;
    margin: 0 auto;
    margin-bottom: 50px;
    @include respond-to("ex_large_1600") {
      //margin-bottom: 30px;
    }
    @include respond-to("medium_lg") {
      margin-bottom: 30px;
    }

    @include respond-to("small") {
      width: 80%;
      margin-bottom: 30px;
      font-size: 14px;
    }
  }
}
.foot-note {
  width: 50%;
  margin: 0px auto;
  text-align: center;
  padding: 32px 0px;
  color: #808080;
  font-size: 14px;
  font-weight: 200;
  @include respond-to("small") {
    font-size: 14px;
    width: 90%;
  }
}
//-------404 page------------------//
.pagenotfound-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: #fefefe;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .fourOfour {
    object-fit: contain;
    height: 54%;
    object-position: top;
    width: 100%;
    @include respond-to("medium_lg") {
      height: 30%;
    }
  }
  h2 {
    font-size: 24px;
    opacity: 0.72;
    color: #000000;
    font-family: Poppins;
    font-weight: normal;
    margin-top: 30px;
    @include respond-to("small") {
      align-content: center;
      margin: 10px 39px;
      text-align: center;
      font-size: 20px;
    }
  }
  img {
    margin-top: 29px;
  }
  a {
    background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
    font-size: 18px;
    font-weight: 400;
    padding: 6px 50px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 40px;
  }
}
