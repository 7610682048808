.leadership-wrapper {
  // background-image: linear-gradient(369deg, #ebecec, #ececec);

  .leadership-case-studies-section {
    padding-bottom: 50px;
    .tab-container {
      width: 77%;
      margin: 0 auto;
      padding: 40px 40px;
      background-color: $white-color;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      min-height: 800px;
      //height: 950px;
      @include respond-to("small") {
        width: 76%;
        min-height: 1100px;
        padding: 20px 40px;
      }
      @include respond-to("exsmall_320") {
        width: 74%;
        min-height: 1095px;
      }
      position: relative;
      .tab-header {
        display: flex;
        width: 35%;
        margin: 0 auto 0px 18px;
        justify-content: space-between;
        @include respond-to("ex_large") {
          width: 50%;
        }
        @include respond-to("ex_large_1600") {
          width: 62%;
          margin: 0 auto 0px 14px;
        }
        @include respond-to("large_1300") {
          width: 64%;
        }
        @include respond-to("large") {
          width: 80%;
        }
        @include respond-to("medium") {
          width: 76%;
        }
        @include respond-to("small") {
          display: block;
        }
        .tab-title {
          display: flex;
          align-items: center;
          // width: 25%;
          margin: 24px 0px;
          cursor: pointer;
          @include respond-to("medium_lg") {
            // width: 32%;
          }
          @include respond-to("medium") {
            // width: 28%;
          }
          @include respond-to("small") {
            width: 50%;
            margin: 15px 0px;
          }
          span {
            width: 5px;
            height: 40px;
            border-radius: 50px;
            background-image: linear-gradient(to bottom, #f37021, #fddb31);
            @include respond-to("small") {
              height: 30px;
            }
          }
          h2 {
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #000000;
            opacity: 0.9;
            margin-left: 16px;
            @include respond-to("medium") {
              font-size: 24px;
            }
            @include respond-to("small") {
              font-size: 24px;
              width: 81%;
            }
            @include respond-to("exsmall_320") {
              font-size: 22px;
              width: 75%;
            }
          }
          &.inactive {
            opacity: 0.3;
            filter: grayscale(100%);
          }
        }
      }
      .feature-card-container {
        // width: 77%;
        // margin: 0 auto;
        // padding: 40px 40px;
        @include respond-to("small") {
          // padding-top: 12px;
        }
        @include respond-to("exsmall_320") {
        }
        .sub-title-block {
          width: 92%;
          margin: 0px auto;
          // padding: 14px 0px;
          p {
            // text-align: center;
            font-size: 16px;
            @include respond-to("exsmall_320") {
              font-size: 14px;
            }
          }
        }
        .card-grid {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          flex-flow: row wrap;
          @include respond-to("medium") {
            display: block;
          }
          .card-wrap {
            width: 30%;
            margin: 14px auto;
            background-color: $white-color;
            // margin: 24px 0px;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
            border-radius: 8px;
            padding-bottom: 24px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            &.none-active {
              max-height: 420px;
              @include respond-to("medium") {
                max-height: 425px;
              }
              @include respond-to("small") {
                max-height: 356px;
              }
            }
            @include respond-to("medium_lg") {
              padding-bottom: 12px;
            }
            @include respond-to("medium") {
              width: 100%;
            }
            @include respond-to("small") {
              width: 100%;
              padding-bottom: 16px;
            }
            &:hover {
              // box-shadow: 0 0 24px 12px #d6d6d6;
              // transform: scale(1.05);
            }
            .card-main-section {
              min-height: 346px;
              &.none-active {
                max-height: 346px;
              }
              @include respond-to("medium_lg") {
                min-height: 236px;
              }
              @include respond-to("medium") {
                min-height: 230px;
              }
              @include respond-to("small") {
                min-height: 202px;
              }
              .card-image-section {
                img {
                  width: 100%;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                }
              }
              .card-detail-section {
                padding: 20px 0px;
                @include respond-to("medium") {
                  padding: 12px 0px;
                }
                .title-section {
                  // min-height: 100px;
                  .card-main-title {
                    padding: 0px 12px;
                    h2 {
                      font-size: 16px;
                      margin: 0 auto;
                      font-weight: 600;
                      @include respond-to("medium") {
                        font-size: 12px;
                      }
                      @include respond-to("medium") {
                        font-size: 14px;
                      }
                    }
                  }
                  .card-sub-title {
                    padding: 0px 12px;
                    h3 {
                      font-size: 13px;
                      margin: 0 auto;
                      font-weight: 400;
                    }
                  }
                }

                .card-description {
                  display: none;

                  padding: 24px 12px 12px;
                  p {
                    font-size: 13px;
                    font-weight: 300;
                    text-align: justify;
                  }
                  &.open {
                    display: block;
                  }
                }
              }
            }
            .card-arrow {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-end;
              align-content: center;
              align-items: center;
              // object-fit: contain;
              //   margin: 0px auto;
              width: 95%;
              padding-top: 5%;
              padding-bottom: 12px;

              .btn {
                padding: 0px 24px;
                @include respond-to("medium") {
                  padding: 0px 12px;
                }
                span {
                  color: $primary-color;
                  font-weight: 500;
                  @include respond-to("medium") {
                    font-size: 12px;
                  }
                }
              }
              .arrow-container {
                img {
                  width: 48px;
                  height: 48px;
                  border-radius: 24px;
                  background-color: white;
                  border: 1px solid #ddd;
                  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
                  right: 24px;
                  @include respond-to("medium") {
                    width: 40px;
                    height: 40px;
                  }
                }
              }
            }
          }
        }
        .sub-description {
          margin: 60px 0px 24px;
          align-content: center;
          align-items: center;

          p {
            text-align: center;
          }
        }
        .btn-wrapper {
          // text-align: center;
          margin: 24px 0px;
          a {
            button {
              background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
              border: none;
              display: inline-block;
              text-align: center;
              border-radius: 4px;
              padding: 12px 20px;
              //width: 165px;
              font-size: 16px;
              font-weight: 300;
              outline: none;
              cursor: pointer;
              color: #ffffff;
              // margin: 14px auto;
            }
          }
        }
        .events-container {
          width: 100%;

          // text-align: center;
          // margin: 0;
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);

          .events-card-grid {
            margin: 40px 16px;
            @include respond-to("small") {
              margin: 40px 0px;
            }
            .events-card {
              margin: 14px auto;
              background-color: $white-color;
              box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
              border-radius: 8px;
              display: flex;
              padding: 24px;
              &:hover {
                transform: scale(1.03);
                transition: 0.2s ease-in-out;
              }
              @include respond-to("small") {
                padding: 14px;
              }
              .event-date {
                width: 15%;
                // padding: 0px 12px;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                @include respond-to("medium") {
                  width: 20%;
                }
                @include respond-to("small") {
                  width: 45%;
                }
                h3 {
                  font-size: 24px;
                  font-weight: 500;
                  text-align: center;
                  color: gray;
                  line-height: 1.38;
                  @include respond-to("small") {
                    font-size: 20px;
                  }
                }
                p {
                  text-align: center;
                  font-size: 16px;
                  @include respond-to("medium") {
                    font-size: 14px;
                  }
                  @include respond-to("small") {
                    font-size: 12px;
                  }
                }
              }

              .event-discription {
                width: 85%;
                border-left: 2px solid $primary-color;
                padding: 0px 12px;
                display: flex;
                align-items: center;
                @include respond-to("medium") {
                  width: 80%;
                }
                @include respond-to("medium") {
                  width: 55%;
                }
                p {
                  text-align: left;
                  font-size: 16px;
                  @include respond-to("medium") {
                    font-size: 14px;
                  }
                  a{
                    color: $primary-color;
                    text-decoration: none;
                    font-weight: 500;
                  }
                  i{
                    color: $primary-color;
                    text-decoration: none;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }

        //============Subscribe section===========
        .subscribe-container {
          width: 80%;
          text-align: left;
          margin: 0;
          position: absolute;
          top: 54%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include respond-to("small") {
            top: 57%;
            width: 98%;
          }
          @include respond-to("exsmall_320") {
            top: 57%;
          }
          .modal {
            width: 80%;
            z-index: 100;
            background: white;
            position: relative;
            margin: 1.75rem auto;
            border-radius: 3px;
            padding: 50px 40px;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

            @include respond-to("medium") {
              // width: 80%;
            }
            @include respond-to("small") {
              // width: 80%;
              padding: 20px 28px;
            }
            .modal-header {
              display: flex;
              justify-content: flex-end;

              .modal-close-button {
                font-size: 1.4rem;
                font-weight: 700;
                line-height: 1;
                color: #000;
                opacity: 0.3;
                cursor: pointer;
                border: none;
                background-color: $white-color;
              }
              .button-default {
                background: #247ba0;
                color: #fff;
              }
            }
            .modal-body {
              .form {
                .discription-text {
                  p {
                    text-align: center;
                  }
                }
                .input-box-wrap {
                  display: flex;
                  @include respond-to("small") {
                    display: flex;
                    flex-flow: column;
                  }
                  .name {
                    width: 50%;
                    @include respond-to("small") {
                      width: 97%;
                    }
                    input[type="text"] {
                      width: 90%;
                      color: #c2c2c2;
                      //   padding: 12px 0px;
                      margin: 8px 0;
                      box-sizing: border-box;
                      border: none;
                      border-bottom: 2px solid #e5e5e5;
                      font-size: 14px;
                      font-weight: 300;
                      @include respond-to("small") {
                        width: 97%;
                      }
                    }
                    input[type="email"] {
                      width: 90%;
                      color: #c2c2c2;
                      //   padding: 12px 0px;
                      margin: 8px 0;
                      box-sizing: border-box;
                      border: none;
                      border-bottom: 2px solid #e5e5e5;
                      font-size: 14px;
                      font-weight: 300;
                      @include respond-to("small") {
                        width: 97%;
                      }
                    }
                    input[type="number"] {
                      width: 90%;
                      color: #c2c2c2;
                      //   padding: 12px 0px;
                      margin: 8px 0;
                      box-sizing: border-box;
                      border: none;
                      border-bottom: 2px solid #e5e5e5;
                      font-size: 14px;
                      font-weight: 300;
                      @include respond-to("small") {
                        width: 97%;
                      }
                    }
                    input:focus {
                      width: 97%;
                      color: $black-color;
                      //   padding: 12px 0px;
                      margin: 8px 0;
                      outline: none;
                      border-bottom: 2px solid #e5e5e5;
                      font-size: 14px;
                      font-weight: 300;
                      opacity: 0.8;
                    }
                  }
                }
                .fullwidth {
                  display: block;
                  width: 100%;
                  @include respond-to("small") {
                    width: 97%;
                  }
                  input[type="text"] {
                    width: 90%;
                    color: #c2c2c2;
                    //   padding: 12px 0px;
                    margin: 8px 0;
                    box-sizing: border-box;
                    border: none;
                    border-bottom: 2px solid #e5e5e5;
                    font-size: 14px;
                    font-weight: 300;
                    @include respond-to("small") {
                      width: 97%;
                    }
                  }
                }

                input[type="text"]:focus {
                  width: 97%;
                  color: $black-color;
                  //   padding: 12px 0px;
                  margin: 8px 0;
                  outline: none;
                  border-bottom: 2px solid #e5e5e5;
                  font-size: 14px;
                  font-weight: 300;
                  opacity: 0.8;
                }

                input[type="submit"] {
                  background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
                  color: white;
                  padding: 12px 20px;
                  border: none;
                  border-radius: 4px;
                  cursor: pointer;
                  margin: 10px 0;
                  font-size: 15px;
                  font-weight: 500;
                  @include respond-to("small") {
                    align-items: center;
                    text-align: center;
                    padding: 12px 40px;
                    margin: 10% 26%;
                  }
                  @include respond-to("exsmall_320") {
                    margin: 10% 23%;
                  }
                }

                label {
                  font-size: 14px;
                  font-weight: 500;
                  text-align: left;
                  color: #222222;
                  padding-bottom: 15px;
                  display: inline-block;
                  padding-top: 30px;
                  @include respond-to("small") {
                    padding-bottom: 0;
                  }
                }
                .checkbox-label {
                  padding-top: 0px;
                }
                textarea {
                  width: 97%;
                  color: #c2c2c2;
                  opacity: 0.55;
                  box-sizing: border-box;
                  border: none;
                  border-bottom: 2px solid #e5e5e5;
                  font-size: 14px;
                  font-weight: 300;
                  font-family: "Poppins";
                  @include respond-to("small") {
                    padding-top: 10px;
                  }
                }
                textarea:focus {
                  width: 97%;
                  color: $black-color;
                  opacity: 0.8;
                  box-sizing: border-box;
                  border: none;
                  outline: none;
                  border-bottom: 2px solid #e5e5e5;
                  font-size: 14px;
                  font-weight: 300;
                  font-family: "Poppins";
                  @include respond-to("small") {
                    padding-top: 10px;
                  }
                }
              }
              .modal-footer {
                p {
                  font-size: 12px;
                  a {
                    color: $primary-color;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
