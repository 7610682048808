.select-transaction-wrapper {
  margin: 50px 0;
  .transaction-card-wrapper {
    width: 70%;
    margin: 0 auto;
    @include respond-to("ex_large_1600") {
      width: 76%;
    }
    @include respond-to("large") {
      width: 85%;
    }
    @include respond-to("medium_lg") {
      width: 76%;
    }
    @include respond-to("small") {
      width: 70%;
    }
    @include respond-to("exsmall_574") {
      width: 60%;
    }
  }
}
.flip-card {
  height: 272px;
  perspective: 1000px;
  cursor: pointer;
  @include respond-to("ex_large_max") {
    height: 262px;
  }
  @include respond-to("large_1300") {
    height: 290px;
  }
  @include respond-to("large") {
    // height: 300px;
  }
  @include respond-to("medium_lg") {
    height: 328px;
  }
  @include respond-to("exsmall") {
    height: 330px;
  }
  &.active {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.flip-card-inner {
  position: relative;
  height: 96%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  width: 96%;
  margin: 8px auto 0px;
}

.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  //   border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  // align-items: center;
}

.flip-card-front {
  color: black;
  background-color: white;
  .card-items {
    .card-header {
      display: flex;
      justify-content: center;
      padding: 12px 0px;
      img {
        margin: 0px 4px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      }
    }
    .header-line {
      align-items: center;
      border: 0.5px solid #d9d9d9;
      width: 90%;
      margin: 0 auto;
    }
    .card-body {
      .card-logos {
        padding: 12px 0px;

        img {
          margin: 0 auto;
          height: 40px;
        }
      }
      .card-logos-large {
        padding: 6px 0px;
        img {
          margin: 0 auto;
          height: 50px;
        }
      }
      .card-logos-specific {
        padding: 20px 0px 4px;
        img {
          margin: 0 auto;
          height: 40px;
        }
      }
      .card-logo-non {
        padding: 12px 0px;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 40px;
        span {
          color: $white_color;
          background-color: $black_color;
          padding: 6px 8px;
          font-size: 12px;
          font-weight: 500;
          border-radius: 6px;
        }
      }
      .card-middle {
        margin: 0 auto;
        width: 50%;
        text-align: center;
        justify-content: center;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        @include respond-to("ex_large_max") {
          width: 80%;
        }
        p {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}
.flip-card-back {
  background-color: white;
  color: black;
  transform: rotateY(180deg);
  .card-list {
    text-align: left;
    padding: 24px 32px 24px 40px;
    height: 76%;
    overflow: hidden;
    overflow-y: scroll;
    @include scrollBar;
    // &::before {
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   background: linear-gradient(transparent 80%, #ffffff);
    //   border-bottom-right-radius: 8px;
    //   border-bottom-left-radius: 8px;
    // }
    @include respond-to("medium_lg") {
      padding: 24px 32px 24px 40px;
    }
    @include respond-to("exsmall_320") {
      padding: 24px 32px;
    }

    ul {
      transition: 5s ease-in;
      transition-delay: 5s;
      &.active {
        animation-name: scroll;
        animation-duration: 2s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
        scroll-behavior: smooth;
      }
      li {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #000;
        @include respond-to("medium_lg") {
          font-size: 12px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 300;
        padding-bottom: 12px;
        a {
          color: $primary_color;
          cursor: pointer;
        }
      }
    }
  }
}
.slick-slide,
.slick-slide * {
  outline: none !important;
  &:focus {
    outline: none !important;
  }
  &:focus-visible {
    outline: none !important;
  }
  div {
    outline: none !important;
  }
}
