//---------------team-card styles---------------//
.team-card-wrapper {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 50px;
  @include respond-to("ex_large_max") {
    width: 90%;
  }
  @include respond-to("medium") {
    width: 100%;
    margin-bottom: 40px;
  }
  @include respond-to("small") {
    display: none;
  }

  .team-card {
    overflow: hidden;
    text-decoration: none;
    //width: 28%;
    width: calc(34% - 83px);
    // height: 478px;
    min-height: 325px;
    background-color: $white-color;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-delay: 0.01s;
    transition-timing-function: ease-in-out;
    transform-origin: 0%;
    @include respond-to("ex_large") {
      height: 478px;
    }
    @include respond-to("ex_large_1600") {
      height: 400px;
    }
    @include respond-to("ex_large_max") {
      width: calc(30% - 64px);
      height: 325px;
    }

    @include respond-to("medium_lg") {
      width: calc(30% - 50px);
    }
    .member-img {
      opacity: 1;
      img {
        width: 100%;
      }
      .team-img2 {
        width: 100%;
      }
    }

    .cad-details-box {
      margin: 0 auto;
      text-align: center;
      padding: 24px;
      .member-name {
        .name {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          color: $black-color;
          margin: 0;
        }
      }
      .member-position {
        .title {
          text-align: center;
          font-size: 16px;
          color: #555555;
        }
      }
    }
    .inner-card {
      visibility: hidden;
      opacity: 0;
      display: none;
      position: relative;

      .cover-image {
        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 180px;
        }
      }
      .title-section {
        display: flex;
        height: 83px;
        .casual-img {
          position: absolute;
          //top: 20%;
          top: 79px;
          left: 4%;
          border-radius: 8px;
        }
        .member-photo-hide {
          visibility: hidden;
        }
        .title-wrapper {
          display: flex;
          width: 100%;
          padding-left: 38%;
          justify-content: space-between;
          .title {
            flex: 2;
            @include cad_text_faid;
            .name {
              font-size: 18px;
              font-weight: 600;
            }
            .title {
              font-size: 17px;
              font-weight: normal;
            }
          }
          .icon-set {
            flex: 1;
            display: flex;
            justify-content: center;
            .tooltip {
              position: relative;
              display: inline-block;
              .tooltip-text {
                visibility: hidden;
                width: 120px;
                background-color: $primary-color;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                font-size: 12px;

                /* Position the tooltip */
                position: absolute;
                z-index: 1;
                top: 45%;
                left: 50%;
                margin-left: -70px;
                &::after {
                  content: "";
                  position: absolute;
                  bottom: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent transparent #f37021 transparent;
                }
              }
              .tooltip-email {
                visibility: hidden;
                width: 232px;
                background-color: $primary-color;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                font-size: 12px;

                /* Position the tooltip */
                position: absolute;
                z-index: 1;
                top: 45%;
                left: 0%;
                margin-left: -186px;
                &::after {
                  content: "";
                  position: absolute;
                  bottom: 100%;
                  left: 88%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent transparent #f37021 transparent;
                }
              }
              &:hover {
                .tooltip-text {
                  visibility: visible;
                }
                .tooltip-email {
                  visibility: visible;
                }
              }
            }

            @include cad_text_faid;
            .icon {
              width: 18px;
              padding: 6px;
              background-color: $white-color;
              margin: 0px 4px;
              border-radius: 8px;
              box-shadow: 0 0 12px 0 rgba(51, 12, 12, 0.16);
            }
            .social-icon {
              color: $primary-color;
              font-size: 20px;
              margin-right: 21px;
              border-radius: 8px;
              box-shadow: 0 0 12px 0 rgba(51, 12, 12, 0.16);
              padding: 5px;
            }
          }
        }
      }
      .desc {
        @include card_desc_fade;
        padding: 20px;
        opacity: 0;

        transition-delay: 0.3s;
        .desc-text {
          font-size: 16px;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          overflow: hidden;
          line-height: 24px;
          text-align: justify;
        }
        span {
          a {
            left: 50%;
            color: $primary-color;
            line-height: 1.5;
            opacity: 0.7;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            float: right;
            padding: 9px;
            margin: auto 20px;
            display: block;
          }
        }
      }
    }
    &.active {
      transform-origin: 0%;
      width: 552px;
      height: auto;
      .member-img {
        display: none;
      }
      .cad-details-box {
        display: none;
      }
      .inner-card {
        visibility: visible;
        opacity: 1;
        display: block;
        @include inner_card_fade;
      }
      .desc {
        opacity: 1;
      }
    }
    &.none-active {
      width: calc(26% - 32px);
      // height: 400px;
      @include respond-to("ex_large") {
        height: 400px;
      }
      @include respond-to("ex_large_1600") {
        height: 358px;
      }
      @include respond-to("ex_large_max") {
        width: calc(28% - 54px);
        height: 322px;
      }
    }
  }
}
//-----------Our Team card mobile view styles-------------------
.second-card-mobile-wrapper {
  display: none;
  padding: 40px 6px;
  @include respond-to("small") {
    display: block;
    padding: 30px 6px;
  }
  @include respond-to("small") {
    padding: 30px 6px 10px;
  }
  .team-card {
    .card_containar {
      margin: 10px 10px 20px 10px;
      border-radius: 8px;
      // height: 183px;
      // overflow: hidden;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
      @include respond-to("small") {
        margin: 10px 4px 20px 4px;
      }
      // @include transitinTime;
      .first-line {
        display: flex;
        .image_containar {
          //display: block;
          visibility: visible;
          opacity: 1;
          @include transitinTime;
          transition-duration: 0.08s;
          height: 100%;
          // filter: grayscale(1);
          width: 50%;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          object-fit: cover;
          object-position: center;
          @include respond-to("small") {
            width: 41%;
          }
          @include respond-to("exsmall") {
            width: 45%;
          }
          @include respond-to("exsmall_320") {
            width: 47%;
          }
        }
        .casual_image_containar {
          //display: none;
          visibility: hidden;
          opacity: 0;
          width: 0;
          height: 157px;
          @include transitinTime;
          transition-duration: 0.08s;
          // height: 100%;
          // filter: grayscale(1);
          // width: 50%;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          object-fit: cover;
          object-position: center;
        }
        .leftside_text {
          @include transitinTime;
          display: flex;
          align-items: center;
          background-color: #ffffff;
          width: 100%;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          position: relative;
          .arrow {
            position: absolute;
            @include transitinTime;
            opacity: 1;
            transform: rotate(180deg);
            top: 5px;
            right: 6px;
            width: 25px;
          }
          .card_list_items {
            @include transitinTime;
            padding: 0px 10px;
            object-fit: contain;
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #000000;

            .name {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.57;
              letter-spacing: 0.5px;
              color: #191515;
            }
            .title {
              font-size: 12px;
              font-weight: normal;
              line-height: 1.92;
              letter-spacing: 0.43px;
              color: #191515;
              margin-bottom: 5px;
              //height: 45px;
            }
            .icon-set {
              //width: 88%;
              // flex: 1;
              display: flex;
              // justify-content: flex-end;
              .tooltip {
                position: relative;
                display: inline-block;
                .tooltip-text {
                  visibility: hidden;
                  width: 120px;
                  background-color: $primary-color;
                  color: #fff;
                  text-align: center;
                  border-radius: 6px;
                  padding: 5px 0;
                  font-size: 12px;

                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  top: 125%;
                  left: 50%;
                  margin-left: -70px;
                  &::after {
                    content: "";
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent transparent #f37021 transparent;
                  }
                }
                .tooltip-email {
                  visibility: hidden;
                  width: 220px;
                  background-color: $primary-color;
                  color: #fff;
                  text-align: center;
                  border-radius: 6px;
                  padding: 5px 0;
                  font-size: 12px;

                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  top: 125%;
                  left: 50%;
                  margin-left: -160px;
                  &::after {
                    content: "";
                    position: absolute;
                    bottom: 100%;
                    left: 70%;
                    margin-left: -10px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent transparent #f37021 transparent;
                  }
                }
                &:hover {
                  .tooltip-text {
                    visibility: visible;
                  }
                  .tooltip-email {
                    visibility: visible;
                  }
                }
              }
              @include cad_text_faid;
              .icon {
                width: 20px;
                margin: 0px 20px 0px 0px;
                padding: 7px;
                background-color: $white-color;
                border-radius: 8px;
                box-shadow: 0 0 12px 0 rgba(51, 12, 12, 0.16);
                @include respond-to("exsmall_320") {
                  width: 18px;
                  margin: 0px 8px 0px 0px;
                }
              }
              .social-icon {
                color: $primary-color;
                font-size: 20px;
                margin-right: 21px;
                border-radius: 8px;
                box-shadow: 0 0 12px 0 rgba(51, 12, 12, 0.16);
                padding: 5px;
                @include respond-to("exsmall_320") {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }

      .desc {
        p {
          @include transitinTime;
          opacity: 0;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.14;
          letter-spacing: normal;
          color: #000000;
          padding: 12px;
          text-align: justify;
          @include respond-to("small") {
            //line-height: 1.4;
            line-height: 20px;
            padding: 20px;
          }
        }
      }
    }

    &.active {
      &.no0 {
        .card_containar {
          // height: 810px;
          // @include respond-to("small") {
          //   height: ;
          // }
          // @include respond-to("exsmall_574") {
          //   height: ;
          // }
          @include respond-to("exsmall_574") {
            // height: 901px;
          }
          @include respond-to("exsmall") {
            // height: 953px;
          }
          @include respond-to("exsmall_320") {
            // height: 1045px;
          }
          @include mq(landscape) {
            // height: 546px;
          }
        }
      }
      &.no1 {
        .card_containar {
          // height: 1054px;
          // @include respond-to("small") {
          //   height: ;
          // }
          @include respond-to("exsmall_574") {
            // height: 1102px;
          }
          @include respond-to("exsmall") {
            // height: 1130px;
          }
          @include respond-to("exsmall_320") {
            // height: 1268px;
          }
          @include mq(landscape) {
            // height: 946px;
            // @include respond-to("small") {
            //   height: 643px;
            // }
          }
        }
      }
      &.no2 {
        .card_containar {
          // height: 1200px;
          // @include respond-to("small") {
          //   height: 1490px;
          // }
          @include respond-to("exsmall_574") {
            // height: 1230px;
          }
          @include respond-to("exsmall") {
            // height: 1304px;
          }
          @include respond-to("exsmall_320") {
            // height: 1495px;
          }
          @include mq(landscape) {
            // height: 726px;
          }
        }
      }
      &.no3 {
        .card_containar {
          // height: 790px;
          // @include respond-to("small") {
          //   height: ;
          // }
          @include respond-to("exsmall_574") {
            // height: 803px;
          }
          @include respond-to("exsmall") {
            // height: 843px;
          }
          @include respond-to("exsmall_320") {
            // height: 943px;
          }
          @include mq(landscape) {
            // height: 314px;
          }
        }
      }
      &.no4 {
        .card_containar {
          // height: 812px;
          // @include respond-to("small") {
          //   height: ;
          // }
          @include respond-to("exsmall_574") {
            // height: 850px;
          }
          @include respond-to("exsmall") {
            // height: 886px;
          }
          @include respond-to("exsmall_320") {
            // height: 1026px;
          }
          @include mq(landscape) {
            // height: 544px;
          }
        }
      }
      &.no5 {
        .card_containar {
          // height: 642px;
          // @include respond-to("small") {
          //   height: ;
          // }
          @include respond-to("exsmall_574") {
            // height: 566px;
          }
          @include respond-to("exsmall") {
            // height: 600px;
          }
          @include respond-to("exsmall_320") {
            // height: 674px;
          }
          @include mq(landscape) {
            // height: 469px;
          }
        }
      }

      // overflow: hidden;
      .card_containar {
        background-color: #ffffff;
        // height: 345px;
        .first-line {
          .image_containar {
            transform: scale(1);
            //@at-root: 10px;
            //filter: grayscale(0);
            border-radius: 8px;
            //width: 45%;
            //display: none;
            visibility: hidden;
            opacity: 0;
            width: 0;
            height: 0;
            @include respond-to("small") {
              // width: 40%;
            }
          }
          .casual_image_containar {
            //display: block;
            visibility: visible;
            opacity: 1;
            transform: scale(1);
            margin: 10px;
            border-radius: 8px;
            width: 141px;
            height: 157px;
            // @include respond-to("small") {
            //   width: 40%;
            // }
          }
          .leftside_text {
            background-color: transparent;
            @include respond-to("small") {
              padding: 0 2px;
            }
            @include respond-to("exsmall") {
              padding: 0 3px;
            }

            .arrow {
              opacity: 1;
              transform: rotate(0deg);
            }
            .card_list_items {
              padding: 30px 0 0;
            }
          }
        }

        .desc {
          p {
            opacity: 1;
          }
        }
      }
    }
  }
}
.senior-team-wrapper {
  .btn-join-now {
    text-align: center;
    a {
      button {
        background: linear-gradient(343deg, #f37021 6%, #fddb31 95%);
        border: none;
        display: inline-block;
        text-align: center;
        border-radius: 4px;
        padding: 12px 20px;
        //width: 165px;
        font-size: 16px;
        font-weight: 300;
        outline: none;
        cursor: pointer;
        color: #ffffff;
        margin: 14px auto;
      }
    }
  }
}
