//======= map styles ==========//

.map-container {
  width: 40%;
  margin: auto 0px 0px;
  @include respond-to("medium_lg") {
    width: 90%;
    margin: 66px auto 0px;
  }
  .country-select-tab-wrapper {
    background-color: $white-color;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    .country-select-tab {
      display: flex;
      // width: 100%;
      // margin: 0px 42px;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 6px;
      // background-color: $white-color;
      // box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
      z-index: 2;
      // margin-top: 120px;

      // @include respond-to("ex_large") {
      //   margin-top: 120px;
      // }
      // @include respond-to("ex_large_1600") {
      //   margin-top: 96px;
      // }
      // @include respond-to("ex_large_max") {
      //   //width: 97%;
      //   margin-top: 24px;
      // }
      .tab-card {
        transition-duration: 0.4s;
        transition-delay: 0.1s;
        background-color: $white-color;
        padding: 36px 0px;
        width: 17%;
        color: #848484;
        text-align: center;
        border-radius: 6px;
        border: solid 2px transparent;
        cursor: pointer;
        @include respond-to("ex_large") {
          width: 25%;
          padding: 36px 0px;
        }
        @include respond-to("ex_large_1600") {
          padding: 24px 0px;
        }
        @include respond-to("ex_large_max") {
          padding: 18px 0px;
        }

        @include respond-to("medium_lg") {
          padding: 12px 0px;
        }
        @include respond-to("small") {
          padding: 12px 0px;
        }
        &.active {
          box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
          transform: scale(1.05);
          border: solid 2px;
          animation: fade-in-keyframes 0.2s ease-in-out;
          @keyframes fade-in-keyframes {
            from {
              opacity: 0;
              transform: scale(0.8);
              transform: translateY(0px);
            }
            to {
              opacity: 1;
              transform: scale(1);
              transform: translateY(-14px);
            }
          }

          //margin-bottom: 24px;
          transform: translateY(-14px);
          color: $primary-color;
        }
        &.none-active {
          transition-duration: 0.4s;
          transition-delay: 0.1s;
          background-color: $white-color;
          padding: 12px 6px;
          width: 17%;
          color: #848484;
          text-align: center;
          border-radius: 6px;
          border: solid 2px transparent;
          cursor: pointer;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          @include respond-to("ex_large_max") {
            font-size: 12px;
          }
          @include respond-to("small") {
            font-size: 8px;
          }
        }
      }
    }
    .sub-heading {
      margin: 0px auto;
      color: #848484;
      font-size: 16px;
      font-weight: 600;
      padding: 0px 0px 36px 0px;
      @include respond-to("ex_large") {
        padding: 0px 0px 36px 0px;
      }
      @include respond-to("ex_large_1600") {
        padding: 0px 0px 24px 0px;
      }
      @include respond-to("ex_large_max") {
        padding: 0px 0px 18px 0px;
      }
      @include respond-to("medium_lg") {
        padding: 0px 0px 12px 0px;
      }
      @include respond-to("small") {
        padding: 0px 0px 12px 0px;
      }

      a {
        color: #848484;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $primary-color;
          font-weight: 600px;
        }

        &:active {
          color: #848484;
          font-weight: 600px;
        }
      }
    }
  }
}
