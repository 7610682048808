// =========================================
// =========== Header styles ===============
// =========================================

//------ App header styles ------
.App-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  &.bg-black {
    background: #ffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  &.bg-white {
  }
  .main-header {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 25px 0;
    justify-content: space-between;
    @include respond-to("large") {
      // width: 90%;
      display: none;
    }
    @include respond-to("medium") {
      // width: 95%;
      // display: none;
    }
    .logo {
      // flex: 10%;
      img {
        width: 150px;
        margin-top: 10px;
      }
    }
    nav {
      width: 50%;
      margin: 0 auto;
      align-self: flex-end;
      // margin-left: 44%;
      // @include respond-to("ex_large_1600") {
      //   width: 70%;
      //   margin: 0 auto;
      //   // margin-left: 30%;
      // }
      @include respond-to("ex_large_max") {
        width: 60%;
      }
      // @include respond-to("large_1300") {
      //   width: 60%;
      //   margin: 0 auto;
      //   // margin-left: 22%;
      // }
      // @include respond-to("large") {
      //   width: 90%;
      //   margin: 0 auto;
      //   // margin-left: 10%;
      // }

      .nav-list {
        position: relative;
        width: 100%;
        ul {
          display: flex;
          list-style: none;
          align-items: center;
          justify-content: space-between;
          .list {
            a {
              color: #484848;
              text-decoration: none;
              opacity: 0.9;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              align-items: center;
              span {
                width: 15px;
                height: 15px;
                margin-top: 5px;
                border-radius: 50%;
                z-index: 99;
                background-color: transparent;
              }
              &.active {
                color: $primary-color;
                font-weight: 600;
                opacity: 1;
                cursor: default;
                span {
                  background-color: $primary-color;
                }
              }
            }
          }
        }
        @include respond-to("medium") {
          display: none;
        }
      }
    }
  }
  .header-mob {
    // background: $background-color;
    display: none;
    align-items: center;
    // position: fixed;
    // z-index: 9999;
    width: 100%;

    @include respond-to("large") {
      display: flex;
    }
    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      padding: 25px 0;
      position: relative;
      margin: 0 auto;
      @include respond-to("medium") {
        // padding: 25px 0;
      }
      .logo {
        img {
          width: 171px;
          @include respond-to("medium") {
            width: 132px;
          }
          @include respond-to("small") {
            width: 150px;
          }
        }
      }
      .menu {
        display: flex;
        ul {
          display: flex;
          list-style: none;
          align-items: center;
          &.main-menu-list {
            margin: 0;
            @include respond-to("small") {
              display: none;
            }
            .userBtn {
              cursor: pointer;
            }
            &:active {
              color: $primary-color;
            }
          }

          li {
            padding: 0px 10px;
            @include respond-to("medium") {
              padding: 0 10px;
            }
            .selectedLink {
              color: $dark;
              &:active {
                color: $primary-color;
              }
            }
            a {
              color: $black-color;
              // font-size: 1px;
              text-decoration: none;
              cursor: pointer;
              &:active {
                color: $primary-color;
                font-weight: 600px;
                // border-bottom:  solid 2px #8eae3f
              }
            }
          }
        }
      }
    }
  }
  .menuIcon {
    display: none;
    @include respond-to("large") {
      display: block;
      padding: 8px 0 0 0;
    }
  }
  #menuToggle {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    a {
      text-decoration: none;
      color: $dark;
      transition: color 0.3s ease;
      cursor: pointer;
      &.active {
        color: $primary-color;
        font-weight: 600;
        cursor: default;
      }
    }
    input {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -7px;
      left: -5px;

      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
      color: $dark;
      &:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, 0);
        background: $dark;
        &:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
        &:nth-last-child(2) {
          transform: rotate(-45deg) translate(0, -1px);
        }
      }
    }
    span {
      display: block;
      width: 25px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: $dark;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      color: $dark;
      &:first-child {
        transform-origin: 0% 0%;
      }
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
  }

  /*
    * Make this absolute positioned
    * at the top left of the screen
    */
  .menu-container {
    display: block;
    position: absolute;
    width: 100vw;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    margin: 20px 0 0 0;
    padding: 18px 0;
    background: $white-color;
    &.menu-black {
      background: #f5f6f6;
    }
    &.menu-white {
      background: $white-color;
    }
    li {
      text-align: center;
      padding: 10px 20px;
      font-size: 16px;
      margin: 0;
    }
  }

  #menu-white {
    display: block;
    position: absolute;
    width: 100vw;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    margin: 20px 0 0 0;
    padding: 18px 0;
    background: $white-color;

    li {
      text-align: center;
      padding: 10px 20px;
      font-size: 16px;
      margin: 0;
    }
  }
  #menu-black {
    display: block;
    position: absolute;
    width: 100vw;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    margin: 20px 0 0 0;
    padding: 18px 0;
    background: #f5f6f6;

    li {
      text-align: center;
      padding: 10px 20px;
      font-size: 16px;
      margin: 0;
    }
  }
  #menuToggle input:checked ~ ul {
    transform: translate(-84%, 0);
    display: block;
    width: 100vw;
    margin: 16px 0 0 0;
    padding: 18px 0;
    @include respond-to("large") {
      transform: translate(-88%, 0);
    }
    @include respond-to("medium_lg") {
      transform: translate(-88%, 0);
    }
    @include respond-to("medium") {
      transform: translate(-87%, 0);
    }
    @include respond-to("small") {
      transform: translate(-84%, 0);
    }
  }
  //-----menu Icon Web ------------//
  .menuIconWeb {
    @include respond-to("medium") {
      display: block;
      padding: 8px 0 0 0;
    }
  }
  #menuToggleWeb {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    a {
      text-decoration: none;
      color: $dark;
      transition: color 0.3s ease;
      cursor: pointer;
      &.active {
        color: $primary-color;
        font-weight: 600;
        cursor: default;
      }
    }
    input {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -7px;
      left: -5px;

      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
      color: $dark;
      &:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, 0);
        background: $dark;
        &:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
        &:nth-last-child(2) {
          transform: rotate(-45deg) translate(0, -1px);
        }
      }
    }
    span {
      display: block;
      width: 25px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: $dark;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      color: $dark;
      &:first-child {
        transform-origin: 0% 0%;
      }
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
  }
  #menuToggleWeb input:checked ~ ul {
    transform: translate(-88%, 0);
    display: block;
    width: 100vw;
    margin: 16px 0 0 0;
    padding: 18px 0;
    @include respond-to("medium_lg") {
      transform: translate(-93%, 0);
    }
    @include respond-to("medium") {
      transform: translate(-87%, 0);
    }
    @include respond-to("small") {
      transform: translate(-84%, 0);
    }
  }
}
